app.controller('ClientesCtrl', ['$rootScope', '$scope', 'Cliente', '$uibModal', 'toastr', 'filterFilter', 'Zona',
  function ($rootScope, $scope, Cliente, $uibModal, toastr, filterFilter, Zona) {
    $rootScope.page_title = 'Clientes';
    var vm = $scope;
    
    vm.clientes = [];
    $scope.pageSize = 40;
    
    
    $scope.sortKey = 'nombre';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    
    var dataZona = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    }
    
    
    //+++++++++++++++++ filtar por zona +++++++++++++++++++++++++++++
    vm.filterByZona = function (zona) {
      vm.promise = Cliente.getByZona(zona.id).success(function (data) {
        $scope.clientes = data.clientes;
        $scope.$watchCollection('clientes', function (lista) {
          $scope.filterList = lista;
        })
      });
      
      
    }
    
    Zona.getAll(dataZona).success(function (response) {
      vm.zonas = response.zonas
    });
    
    vm.getClientes = function () {
      $scope.zona = -1;
      
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
      };
      vm.promise = Cliente.getAll(data).success(function (response) {
        $scope.clientes = response.clientes;
        
        $scope.$watch('search.nombre', function (term) {
          var obj = {nombre: term}
          $scope.filterList = filterFilter(vm.clientes, obj);
          $scope.currentPage = 1;
        });
      });
    };
    
    vm.getClientes();
    
    
    $scope.cliente = {};
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/clientes/form.html',
        controller: 'ClienteModalCtrl',
        size: size,
        resolve: {
          cliente: function () {
            return $scope.cliente;
          }
        }
      });
      
      modalInstance.result.then(function (cliente) {
        //$scope.cliente = res;
        
        if (!cliente.id) {
          //si no existe el cliente
          var record = $scope.cliente;
          record.suscriptor_id = $rootScope.suscriptor.id;
          record.tipo_cliente_id = vm.cliente.tipo_cliente.id;
          record.zona_id = vm.cliente.zona.id;
          record.proveedor_id = vm.cliente.proveedor.id;
          
          $scope.promise = Cliente.create(record).then(function (response) {
            toastr.info('Good!', 'Cliente registrado exitosamente!');
            vm.clientes.push(record);
            vm.cliente = {};
          }, function (error) {
            toastr.error('Error', 'No se pudo guardar la informacion');
          }).$promise;
        } else {
          var record = $scope.cliente;
          record.tipo_cliente_id = vm.cliente.tipo_cliente.id;
          record.zona_id = vm.cliente.zona.id;
          record.proveedor_id = vm.cliente.proveedor.id;
          
          Cliente.update($scope.cliente.id, record).success(function (response) {
            toastr.info("cliente actualizado correctamente!", 'info', {
              closeButton: true
            });
            $scope.cliente = {};
          }).error(function (error) {
            toastr.error("Email o Codigo duplicados", 'Error', {
              closeButton: true
            });
            vm.open();
            
          })
          
          
        }
      }, function () {
        $scope.cliente = {};
      });
    }; //END FUNCTION OPEN
    
    vm.edit = function (cliente) {
      $scope.cliente = cliente;
      //$scope.cliente.codigo = cliente.codigo;
      console.log(cliente);
      vm.cliente.estado = cliente.estado == '1' ? true : false;
      $scope.open();
    };
    
    
    //metodo para seleccionar un cliente
    vm.selectedRow = null;  // initialize our variable to null
    vm.setClickedRow = function (cliente, index) {  //function that sets the value of selectedRow to current index
      vm.selectedRow = index;
      vm.cliente = cliente;
    }
    
    //buscar todas la ventas de los clientes
    vm.getVentas = function (option) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/clientes/ventas.html',
        controller: 'ClienteVentasModalCtrl',
        size: 'lg',
        resolve: {
          option: option,
          cliente: function () {
            return $scope.cliente;
          }
        }
      });
    }
    
    
  }]);


//controller para mostrar las ventas por cliente
app.controller('ClienteVentasModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'cliente', 'option', 'Venta', '$uibModal',
  function ($rootScope, $scope, $uibModalInstance, cliente, option, Venta, $uibModal) {
    var vm = $scope;
    vm.option = option;
    vm.cliente = cliente;
    vm.ventas = [];
    vm.saldo = 0;
    vm.busy = true;
    
    if (vm.option === 1) {
      vm.title = "Ventas con saldo pendiente";
    } else {
      vm.title = "Todas las ventas";
    }
    
    var getVentas = function () {
      var params = {
        cliente_id: vm.cliente.id,
        option: vm.option
      }
      Venta.getVentasByCliente(params).then(function (data) {
        vm.ventas = data.ventas;
        angular.forEach(vm.ventas, function (venta) {
          if (venta.reversada == 0) {
            vm.saldo = vm.saldo + venta.nuevo_saldo;
          }
        });
        vm.busy = false;
      })
      
    };
    
    getVentas();
    
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    //metodo para ver los recaudos de una venta
    vm.showRecaudo = function (venta) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/tools/recaudos.html',
        controller: 'CarteraModalCtrl',
        size: 'lg',
        resolve: {
          venta: function () {
            return venta;
          }
        }
      });
      
    };
    
  }]);

/********************************************************************************************************+*/


app.controller('ClienteModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'cliente', 'Cliente', 'Zona', 'Proveedor',
  function ($rootScope, $scope, $uibModalInstance, cliente, Cliente, Zona, Proveedor) {
    var vm = $scope;
    
    $scope.userIsAdmin = $rootScope.currentUser.admin;
    //lenar los combobox
    Cliente.getType().success(function (response) {
      vm.tipoClientes = response.tipo;
    });
    
    var data = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    }
    
    Zona.getAll({'estado': true}).success(function (response) {
      vm.zonas = response.zonas
    })
    
    Proveedor.getAll(data).then(function (response) {
      vm.proveedores = response.proveedores
    })
    
    
    vm.title = "";
    
    //************************CREAR EL FORMULARIO
    vm.cliente = cliente;
    
    if (!vm.cliente.id) {
      vm.isnew = true;
      vm.cliente.saldo = 0;
      vm.title = "Registrar nuevo cliente!";
    } else {
      vm.title = "Editando a: " + vm.cliente.nombre + "!";
      vm.isnew = false;
    }
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      if (vm.clienteForm.$valid) {
        $uibModalInstance.close(vm.cliente);
      }
      
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
  }]);
