app.controller('RecaudosCtrl', ['$rootScope', '$scope', 'Recaudo', 'toastr', '$confirm', 'Venta', 'Evento', 'Recaudador', 'filterFilter',
  function ($rootScope, $scope, Recaudo, toastr, $confirm, Venta, Evento, Recaudador, filterFilter) {
    $rootScope.page_title = 'Recaudos';
    var vm = $scope;
    vm.isGroup = false;
    vm.textBtnGroup = "Agrupar";
    vm.recaudo = {};
    vm.recaudo_nuevo_saldo = 0;
    
    /******* CONFIGURACION DE LAS FECHA ********/
    vm.today = function () {
      vm.dt1 = new Date();
      vm.dt2 = new Date();
    };
    vm.today();
    
    vm.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.open1 = function () {
      vm.popup1.opened = true;
    };
    
    vm.open2 = function () {
      vm.popup2.opened = true;
    };
    
    vm.popup1 = {
      opened: false
    };
    
    vm.popup2 = {
      opened: false
    };
    
    
    //obtener lista de los recaudadores
    vm.getRecaudadores = function () {
      vm.promise = Recaudador.getAll({suscriptor_id: $rootScope.suscriptor.id})
        .then(function (data) {
          if ($rootScope.currentUser.admin === 1) {
            vm.recaudadores = data.recaudadores;
          } else {
            var obj = {'visible_for_users': 1}
            vm.recaudadores = filterFilter(data.recaudadores, obj);
          }
          
        });
    };
    
    vm.getRecaudadores();
    
    vm.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    };
    
    //buscar los recaudos
    
    vm.buscar = function () {
      vm.getRecaudos();
    };
    
    vm.recaudos = [];
    vm.pageSize = 25;
    vm.currentPage = 1;
    vm.maxSize = 10;
    
    
    vm.sortKey = 'nombre';
    vm.criterio = '';
    
    vm.sort = function (keyname) {
      vm.sortKey = keyname;   //set the sortKey to the param passed
      vm.reverse = !vm.reverse; //if true make it false and vice versa
    };
    
    
    //*********************************************** METODO GETVENTAS********
    vm.getRecaudos = function () {
      vm.isGroup = false;
      vm.textBtnGroup = "Agrupar";
      var params = {
        suscriptor_id: $rootScope.suscriptor.id,
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD')
      };
      vm.promise = Recaudo.getAll(params).then(function (data) {
        vm.recaudos = data.recaudos;
      }).catch(function (data, status) {
        toastr.error('Se ha presentado un error de comunicacion con el servidor.', 'Error');
        vm.ventas = [];
        return status;
      });
      
      
    };
    
    //buscar los recaudos
    vm.getRecaudos();
    
    //metodo para calcular la utilidad
    vm.utilidad = function (recaudo) {
      var tasa = parseFloat(recaudo.recaudador.comision);
      var comision = (recaudo.valor * tasa);
      return comision;
    };
    
    //******** funcion para sumar el total de las ventas ****************
    vm.$watchCollection('filterList', function (list) {
      vm.valores = 0;
      vm.comisiones = 0;
      angular.forEach(list, function (value) {
        if (value.reversado === 0) {
          $scope.valores = $scope.valores + parseFloat(value.valor);
          $scope.comisiones = $scope.comisiones + parseFloat(vm.utilidad(value));
        }
      });
    });
    
    //funcion para validar si un usuario puede reversar un recaudo
    var validarPermisoReversar = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id == 101) {
          vm.reversarAction = true;
        }
      });
    };
    
    validarPermisoReversar();
    
    // FUNCION PARA REVERSAR UNA CONSIGNACION
    vm.reversarRecaudo = function (recaudo) {
      vm.recaudo = recaudo;
      vm.venta = recaudo.venta;
      
      
      var title = "Reversar Recaudo!";
      var texto = "Estas seguro de reversar el recaudo No. :" + recaudo.id + "?";
      
      $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
        .then(function () {
          
          if ((vm.recaudo.valor + vm.recaudo.saldo_venta) === vm.recaudo.venta.valor) {
            vm.saldo_anterior = vm.recaudo.venta.valor;
          } else {
            vm.saldo_anterior = (vm.recaudo.saldo_venta + vm.recaudo.valor);
          }
          
          
          vm.nuevo_saldo = (vm.recaudo.saldo_venta + vm.recaudo.valor);
          
          
          var params = {
            'saldo_anterior': vm.saldo_anterior,
            'nuevo_saldo': vm.nuevo_saldo
          }
          
          
          Venta.update(vm.venta.id, params)
            .then(function (response) {
                Recaudo.update(recaudo.id, {reversado: true})
                  .then(function (data) {
                    
                    vm.recaudo.reversado = true;
                    vm.recaudo.venta.nuevo_saldo = vm.nuevo_saldo;
                    //crear el evento
                    
                    console.log(vm.recaudo);
                    var e = {};
                    e.evento = 'RECAUDO REVERSADO';
                    e.numero = vm.recaudo.id;
                    e.valor = vm.recaudo.valor;
                    e.saldo = vm.nuevo_saldo;
                    e.tipo = "*";
                    e.user_id = $rootScope.currentUser.id;
                    e.suscriptor_id = $rootScope.suscriptor.id;
                    e.proveedor_id = vm.venta.proveedor_id;
                    e.cliente_id = vm.venta.cliente_id;
                    Evento.create(e).then(function (data) {
                      toastr.info('Done!', 'Recaudo reversado correctamente');
                    })
                    
                  }).catch(function (error) {
                  toastr.error('Error', 'No se pudo reversar el Recaudo')
                })
                
              }
            )
            .catch(function (error) {
              toastr.error('Error', 'No se pudo actualizar la venta');
            })
        });
    };//fin remove
    
    
    //metodo para agrupar los recaudos por recaudador
    $scope.groupBy = function () {
      $scope.recaudosAgrupados = [];
      $scope.isGroup = !$scope.isGroup;
      if ($scope.isGroup) {
        $scope.textBtnGroup = "Desagrupar";
        angular.forEach(vm.recaudadores, function (rec) {
          var valor = 0;
          var comision = 0;
          var recaudoAgrupado = {};
          
          
          angular.forEach(vm.recaudos, function (recaudo) {
            if (recaudo.recaudador.id == rec.id && recaudo.reversado === 0) {
              valor = valor + recaudo.valor;
              comision = comision + vm.utilidad(recaudo);
            }
            
            
          }); //finc foreach ventas
          
          recaudoAgrupado.recaudador = rec.nombre;
          recaudoAgrupado.valor = valor;
          recaudoAgrupado.utilidad = comision;
          if (valor > 0) {
            vm.recaudosAgrupados.push(recaudoAgrupado);
          }
          
          
        });
        
      } else {
        $scope.textBtnGroup = "Agrupar";
        
      }
    } //end groupby
    
    
    //metodo para eliminar un recaudo
    vm.eliminarRecaudo = function (recaudo) {
      var title = "Eliminar Recaudo!";
      var texto = "Estas seguro de reversar el recaudo No. :" + recaudo.id + "?";
      $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
        .then(function () {
          vm.promise = Recaudo.delete(recaudo.id).then(function (res) {
            var e = {};
            e.evento = 'RECAUDO ELIMINADO';
            e.numero = recaudo.id;
            e.valor = recaudo.valor;
            e.saldo = recaudo.venta.nuevo_saldo;
            e.tipo = "*";
            e.user_id = $rootScope.currentUser.id;
            e.suscriptor_id = $rootScope.suscriptor.id;
            e.proveedor_id = recaudo.venta.proveedor_id;
            e.cliente_id = recaudo.venta.cliente_id;
            Evento.create(e).then(function (data) {
              toastr.success('Done!', 'Recaudo eliminado correctamente', {
                closeButton: true
              });
              vm.buscar();
            })
          })
        });
    }
    
    
  }]);
