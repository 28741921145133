app.factory('Cliente', ['$http', '$q', function ($http, $q) {
  
  return {
    getAll: function (data) {
      return $http.get('/api/clientes/', {params: data});
    },
    getAllForVentas: function (data) {
      return $http.get('/api/clientsforventas', {params: data});
    },
    getAllForProveedor: function (data) {
      return $http.get('/api/clientsbypro', {params: data});
    },
    getByZona: function (zona_id) {
      return $http.get('/api/clientsbyzona/' + zona_id);
    },
    get: function (id, data) {
      return $http.get('/api/clientes/' + id, {params: data});
    },
    getByCodigo: function (codigo) {
      var defered = $q.defer();
      $http.get('/api/cliente/codigo/' + codigo)
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
    },
    create: function (data) {
      return $http.post('/api/clientes', data);
    }
    ,
    update: function (id, data) {
      return $http.put('/api/clientes/' + id, data);
    },
    getType: function () {
      return $http.get('/api/tipo_clientes/');
    }
  }
}]);
