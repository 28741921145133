
  app.controller('ZonasCtrl', ['$rootScope', '$scope', 'Zona', '$uibModal', 'toastr', 'Pagination',
    function ($rootScope, $scope, Zona, $uibModal, toastr, Pagination) {
      $rootScope.page_title = 'Zonas';
      var vm = $scope;
      vm.pagination = Pagination.getNew(10);

      vm.totalItems = 0;


      $scope.sortKey = 'nombre';
      $scope.criterio = '';

      $scope.sort = function (keyname) {
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
      };

      function getZonas() {
        vm.promise = Zona.getAll().then(function (response) {
          $scope.zonas = response.data.zonas;
          $scope.totalItems = response.data.total;
          $scope.pagination.numPages = Math.ceil($scope.zonas.length / $scope.pagination.perPage);
        });
      };

      getZonas();

      $scope.zona = {};
      $scope.open = function (size) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'templates/zonas/form.html',
          controller: 'ZonaModalCtrl',
          size: size,
          resolve: {
            zona: function () {
              return $scope.zona;
            }
          }
        });

        modalInstance.result.then(function (zona) {
          //$scope.zona = res;

          if (!zona.id) {
            //si no existe el zona
            var record = $scope.zona;
            record.suscriptor_id = $rootScope.suscriptor.id;
            record.recaudador_id = vm.zona.recaudador.id
            $scope.promise = Zona.create(record).then(function (response) {
              toastr.info('Good!', 'Zona registrada exitosamente!');
              vm.zona = null;
              //$scope.bookmarkForm.$setUntouched();
              getZonas();
            }, function (error) {
              toastr.error('Error', 'No se pudo guardar la informacion');
            }).$promise;
          } else {
            var record = $scope.zona;
            record.recaudador_id = vm.zona.recaudador.id
            Zona.update($scope.zona.id, record).then(function (response) {
              toastr.info("zona actualizado correctamente!", 'info', {
                closeButton: true
              });
              $scope.zona = {};
            });
          }
        }, function () {
          $scope.zona = {};
          //$log.info('Modal dismissed at: ' + new Date());
          getZonas();

        });
      }; //END FUNCTION OPEN

      vm.edit = function (zona) {
        $scope.zona = zona;
        vm.zona.estado = zona.estado == '1' ? true : false;
        $scope.open();
      };


    }]);


  app.controller('ZonaModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'zona', 'Recaudador',
    function ($rootScope, $scope, $uibModalInstance, zona, Recaudador) {
      var vm = $scope;

      $scope.userIsAdmin = $rootScope.currentUser.admin;

      vm.title = "";


      //obtener la lista de los recaudadores activos
      function getRecaudadores() {
        var data = {
          suscriptor_id: $rootScope.suscriptor.id,
          estado: true
        };
        vm.promise = Recaudador.getAll(data).then(function (data) {
          $scope.recaudadores = data.recaudadores;
        });
      };

      getRecaudadores();


      //************************CREAR EL FORMULARIO
      vm.zona = zona;

      if (!vm.zona.id) {
        vm.isnew = true;
        vm.zona.saldo = 0;
        vm.title = "Registrar nueva zona!";
      } else {
        vm.title = "Editando a: " + vm.zona.nombre + "!";
        vm.isnew = false;
      }

      //*********** ENVIAR EL FORMULARIO

      vm.ok = function () {
        if (vm.zonaForm.$valid) {
          $uibModalInstance.close(vm.zona);
        }

      };

      vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

    }]);
