app.controller('DescuadresCtrl', ['$rootScope', '$scope', 'toastr', '$confirm', 'Evento', 'Recaudador',
  'Descuadre', '$state', '$uibModal',
  function ($rootScope, $scope, toastr, $confirm, Evento, Recaudador,
            Descuadre, $state, $uibModal) {
    $rootScope.page_title = 'Descuadres';
    var vm = $scope;
    
    $rootScope.recaudadores = [];
    
    
    /******* CONFIGURACION DE LAS FECHA ********/
    vm.today = function () {
      vm.dt1 = new Date();
      vm.dt2 = new Date();
    };
    vm.today();
    
    vm.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.open1 = function () {
      vm.popup1.opened = true;
    };
    
    vm.open2 = function () {
      vm.popup2.opened = true;
    };
    
    vm.popup1 = {
      opened: false
    };
    
    vm.popup2 = {
      opened: false
    };
    
    
    //obtener lista de los recaudadores
    vm.getRecaudadores = function () {
      var params = {
        'suscriptor_id': $rootScope.suscriptor.id,
        'estado': true
      }
      vm.promise = Recaudador.getAll(params)
        .then(function (data) {
          if ($rootScope.currentUser.admin === 1) {
            $rootScope.recaudadores = data.recaudadores;
          } else {
            var obj = {'visible_for_users': 1}
            $rootScope.recaudadores = filterFilter(data.recaudadores, obj);
          }
          
        });
    };
    
    vm.getRecaudadores();
    
    vm.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    };
    
    //buscar los descuadres
    
    vm.buscar = function () {
      vm.getDescuadres();
    };
    
    vm.descuadres = [];
    vm.descuadre = {};
    vm.pageSize = 20;
    vm.currentPage = 1;
    vm.maxSize = 10;
    
    
    vm.sortKey = 'id';
    vm.criterio = '';
    
    vm.sort = function (keyname) {
      vm.sortKey = keyname;   //set the sortKey to the param passed
      vm.reverse = !vm.reverse; //if true make it false and vice versa
    };
    
    
    //metodo ppara buscar descuadres por recaudador
    vm.getDescuadresByRecaudador = function (recaudador) {
      if (recaudador != null) {
        var params = {
          startDate: moment(vm.dt1).format('YYYY-MM-DD'),
          endDate: moment(vm.dt2).format('YYYY-MM-DD'),
          recaudador_id: recaudador.id
        };
        
        vm.promise = Descuadre.getByRecaudador(params).$promise.then(function (data) {
          vm.descuadres = data.descuadres;
        });
      }
      
    };
    
    //*********************************************** METODO GETVENTAS********
    vm.getDescuadres = function () {
      vm.recaudador = -1;
      var params = {
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD')
      };
      
      vm.promise = Descuadre.query(params).$promise.then(function (data) {
        vm.descuadres = data.descuadres;
      });
      
      
    };
    
    //buscar los descuadres
    vm.getDescuadres();
    
    
    //******** funcion para sumar el total de las ventas ****************
    vm.$watchCollection('filterList', function (list) {
      vm.total = 0;
      vm.favor = 0;
      vm.contra = 0;
      angular.forEach(list, function (value) {
        if (value.reversado === 0) {
          if (value.tipo == '+') {
            vm.favor = vm.favor + parseInt(value.valor);
            vm.total = vm.total + parseInt(value.valor);
          } else {
            vm.contra = vm.contra + parseInt(value.valor);
            vm.total = vm.total - parseInt(value.valor);
          }
        }
      });
    });
    
    //funcion para validar si un usuario puede reversar un descuadre
    var validarPermisoReversar = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id == 16) {
          vm.reversarAction = true;
        }
      });
    };
    
    validarPermisoReversar();
    if (!vm.reversarAction) {
      toastr.warning('No esta autorizado para realizar esta operacion', 'Adventencia', {
        closeButton: true
      });
      
      $state.go('dashboard');
    }
    
    
    // FUNCION PARA REVERSAR UNA CONSIGNACION
    vm.reversar = function (descuadre) {
      vm.descuadre = descuadre;
      
      var title = "Reversar Descuadre!";
      var texto = "Estas seguro de reversar el descuadre No. :" + descuadre.id + "?";
      
      $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
        .then(function () {
          descuadre.reversado = true;
          Descuadre.update({id: descuadre.id}, descuadre, function (data) {
            toastr.success('Descuadre reversado exitosamente!', 'Aviso', {
              closeButton: true
            });
          });
          
        });
    };//fin remove
    
    
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/tools/addDescuadre.html',
        controller: 'DescuadreModalCtrl',
        size: size,
        resolve: {
          descuadre: function () {
            return vm.descuadre;
          }
        }
      });
      
      modalInstance.result.then(function (descuadre) {
        
        if (descuadre.id == null) {
          //si no existe el tarjeta
          var record = new Descuadre(); //Step 1
          
          record.valor = descuadre.valor;
          record.tipo_id = descuadre.tipo.id;
          record.reversado = false;
          record.nota = descuadre.nota;
          record.recaudador_id = descuadre.recaudador.id;
          
          record.$save(function (data) { //Step 3
            vm.descuadre = data.descuadre;
            vm.descuadres.push(vm.descuadre); //Step 4
            toastr.success('Descuadre registrado correctamente!', 'Info', {
              closeButton: true
            })
          });
        } else {
          
          var record = {};
          record.valor = descuadre.valor;
          record.tipo_id = descuadre.tipo.id;
          record.reversado = false;
          record.nota = descuadre.nota;
          record.recaudador_id = descuadre.recaudador.id;
          record.reversado = descuadre.reversado;
          
          Descuadre.update({id: descuadre.id}, record, function (data) {
            toastr.success('Descuadre reversado exitosamente!', 'Aviso', {
              closeButton: true
            });
            //vm.descuadre = record;
          })
          
          
        }
      }, function () {
        vm.descuadre = {};
      });
      
    }; // end open
    
    
    vm.editar = function (descuadre) {
      vm.descuadre = descuadre;
      console.log(descuadre);
      vm.descuadre.reversado = descuadre.reversado === 1 ? true : false;
      vm.open();
    }
  }]);


/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

app.controller('DescuadreModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'descuadre', 'TipoDescuadre',
  function ($rootScope, $scope, $uibModalInstance, descuadre, TipoDescuadre) {
    var vm = $scope;
    vm.descuadre = descuadre;
    
    
    vm.tipos = [];
    
    
    vm.promise = TipoDescuadre.query(function (data) {
      vm.tipos = data.tipos;
    })
    
    //cargar la lista de tipodedescuadres
    
    
    if (!vm.descuadre.id) {
      vm.isnew = true;
      vm.title = "Registrar nuevo descuadre";
    } else {
      vm.title = "Editar descuadre: " + vm.descuadre.id;
      vm.isnew = false;
    }
    
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      $uibModalInstance.close(vm.descuadre);
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    
  }]);

