app.factory('Evento', ['$http', '$q', function ($http, $q) {
  return {
    getAll: function (data) {
      return $http.get('/api/eventos/', {params: data});
    },
    get: function (id, data) {
      return $http.get('/api/eventos/' + id, {params: data});
    },
    create: function (data) {
      var defered = $q.defer();
      $http.post('/api/eventos', data)
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (error) {
          defered.reject(error);
        });
      return defered.promise;
    }
  };
}]);
