app.factory('Megared', ['$resource', function ($resource) {
  return $resource('/api/consignaciones/:id',
    {id: "@id"},
    {
      'getTransaction': {
        method: 'GET',
        isArray: false,
        url: '/api/getTransaction',
      },
    });
}]);