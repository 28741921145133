app.controller('EditarVentaCtrl', ['$scope', '$rootScope', 'Venta', 'toastr', '$state', 'Evento',
  function ($scope, $rootScope, Venta, toastr, $state, Evento) {
    $rootScope.page_title = 'Editar venta cargada desde extracto';
    var vm = $scope;
    vm.venta = null;
    vm.editAction = false;
    
    
    //funcion para validar si un usuario puede reversar un descuadre
    var validarPermisoEditarVenta = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id == 91) {
          vm.editAction = true;
        }
      });
    };
    
    validarPermisoEditarVenta();
    if (!vm.editAction) {
      toastr.error('No esta autorizado para realizar esta operacion', 'Adventencia', {
        closeButton: true
      });
      
      $state.go('dashboard');
    }
    
    
    //metodo para buscar la venta
    vm.buscarVenta = function (venta_id) {
      vm.promise = Venta.get(venta_id).then(function (data) {
        vm.venta = data.venta;
        vm.venta.reversada = data.venta.reversada == 1 ? true : false;
        
        //vm.venta.reversada = true;
        
      }).catch(function (error) {
        toastr.error('', 'La venta no se encuentra registrada!', {
          closeButton: true
        });
      })
    } // end buscar venta
    
    
    //editar la venta
    vm.editar = function () {
      var hoy = moment(new Date(), 'YYYY-MM-DD');
      var fecha_venta = moment(vm.venta.created_at, 'YYYY-MM-DD');
      var diff = hoy.diff(fecha_venta, 'days');
      
      //console.log("diferecnia:", diff, "Fecha de la venta:", fecha_venta, "Diff:", diff,'admin',$rootScope.currentUser.admin);
      
      if (diff >= 1 && $rootScope.currentUser.admin === 0) {
        //console.log("no tiene permiso");
        toastr.warning("No estás autorizado. La fecha de la venta supera el numero de días autorizado para su edición!", 'Advertencia', {
          closeButton: true
        });
        return;
      }
      
      //si pasa las validaciones se edita la venta
      var params = {
        valor: vm.venta.valor
      }
      vm.promise = Venta.update(vm.venta.id, vm.venta).then(function () {
        
        //registrar el evento
        var evento = {};
        evento.evento = 'VENTA EDITADA';
        evento.numero = vm.venta.id;
        evento.valor = vm.venta.nuevo_saldo;
        evento.saldo = vm.venta.proveedor.saldo;
        evento.tipo = "*";
        evento.user_id = $rootScope.currentUser.id;
        evento.suscriptor_id = $rootScope.suscriptor.id;
        evento.proveedor_id = vm.venta.proveedor.id;
        evento.cliente_id = vm.venta.cliente.id;
        Evento.create(evento).then(function () {
          
          toastr.success("Venta actualizada correctamente!", 'Good', {
            closeButton: true
          });
          
          setTimeout(function () {
            $('#venta_id').focus().select();
            vm.venta = {};
          }, 200)
        })
        
        
      });
      
      
    }
    
    
  }]);
