app.factory('Tarjeta', ['$http', function ($http) {
  
  return {
    getAll: function (data) {
      return $http.get('/api/tarjetas/', {params: data});
    },
    
    get: function (id, data) {
      return $http.get('/api/tarjetas/' + id, {params: data});
    },
    
    create: function (data) {
      return $http.post('/api/tarjetas', data);
    }
    ,
    
    update: function (id, data) {
      return $http.put('/api/tarjetas/' + id, data);
    }
    
  }
}]);
