app.filter('FilterRecaudadores', function () {
  return function (all, type) {
    var recaudadores = [];
    angular.forEach(all, function (recaudador) {
      if (recaudador.visible_for_users === 1) {
        recaudadores.push(recaudador)
      }
    })
    return recaudadores;
  }
});
