
app.factory('Departamento', ['$http',function ($http) {
  
  return {
    getAll: function () {
      return $http.get('/api/departamentos/');
    },
    find: function (id) {
      return $http.get('/api/departamentos/id/' + id);
    }
  }
}]);
