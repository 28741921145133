
  app.factory('Usuario', ['$http','$q','$timeout',function ($http, $q, $timeout) {
    
    return {
      getAll: function (data) {
        var defered = $q.defer();
        $timeout(function () {
          //$http.get('/api/usuarios/', {params: data})
          $http.get('/api/usuarios/')
            .success(function (data) {
              defered.resolve(data);
            })
            .error(function (error) {
              defered.reject(error);
            })
        }, 10);
        return defered.promise;
        
      },
      
      get: function (id, data) {
        var defered = $q.defer();
        $timeout(function () {
          $http.get('/api/usuarios/' + id, {params: data})
            .success(function (data) {
              defered.resolve(data);
            })
            .error(function (error) {
              defered.reject(error);
            })
        }, 10);
        return defered.promise;
      },
      
      create: function (data) {
        var defered = $q.defer();
        $timeout(function () {
          $http.post('/api/usuarios', data)
            .success(function (data) {
              defered.resolve(data);
            })
            .error(function (error) {
              defered.reject(error);
            })
        }, 10);
        return defered.promise;
      },
      
      update: function (id, data) {
        var defered = $q.defer();
        $timeout(function () {
          $http.put('/api/usuarios/' + id, data)
            .success(function (data) {
              defered.resolve(data);
            })
            .error(function (error) {
              defered.reject(error);
            })
        }, 10);
        return defered.promise;
      },
      setPermiso: function (params) {
        var defered = $q.defer();
        $timeout(function () {
          $http.post('/api/security/user/', params)
            .success(function (data) {
              defered.resolve(data);
            })
            .error(function (error) {
              defered.reject(error);
            })
        }, 10);
        return defered.promise;
      }
      
    }
  }]);
