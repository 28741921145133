
app.controller('VentaModalCtrl2', ['$rootScope', '$scope', '$uibModalInstance',
  'Proveedor', 'Cliente', 'Venta', 'Evento', '$timeout',
  function ($rootScope, $scope, $uibModalInstance, Proveedor, Cliente, Venta, Evento, $timeout) {
    var vm = $scope;


    vm.formValid = false;
    vm.venta = {};
    vm.saldo_proveedor = 0;
    vm.cliente = null;

    $scope.$watch('selectedCliente', function (value) {
      if (value == null) {
        vm.formValid = false;
        vm.venta.tasa = null;
        vm.venta.proveedor = -1;
        vm.venta.valor = null;
        vm.venta.neta = null;

      } else {
        console.log(value);
        var proveedor_id = value.proveedor_id;
        vm.cliente = vm.selectedCliente;
        vm.ventaCreada = false;
        Proveedor.find(proveedor_id).then(function (data) {
          vm.formValid = true;
          vm.venta.proveedor = data.proveedor;
          vm.venta.tasa = value.tasa;
          vm.saldo_proveedor = data.proveedor.saldo;
        });


        setTimeout(function () {
          $("#valor").focus();
        }, 0);
      }
    });


    vm.getProveedorProperties = function (proveedor) {
      if (vm.venta.proveedor != null) {
        Proveedor.find(proveedor.id).then(function (data) {
          vm.venta.proveedor = data.proveedor;
          vm.saldo_proveedor = data.proveedor.saldo;
        });

        vm.venta.tasa = vm.venta.proveedor.tvp;
        calcularVenta();
      }
    };


    $scope.checkIfEnterKeyWasPressed = function ($event) {
      var keyCode = $event.which || $event.keyCode;
      if (keyCode === 13) {
        calcularVenta();
      } else {
        vm.venta.neta = 0;
        vm.formValid = false;
      }

    };


    $scope.tasaEnterKeyWasPressed = function ($event) {
      var keyCode = $event.which || $event.keyCode;
      if (keyCode === 13) {
        $('#valor').focus();
      }

    };


    var calcularVenta = function () {

      var tasa = parseFloat(vm.venta.tasa);
      console.log("ventasCtrl2",tasa);
      var valor = parseInt(vm.venta.valor);
      vm.venta.neta = (valor + (valor * tasa));
      vm.saldo_proveedor = (vm.venta.proveedor.saldo - vm.venta.neta);
      vm.formValid = true;

      setTimeout(function () {
        $('#btnGuardar').focus();
      }, 0);
    };


    /************* LLENAR LOS COMBOS ********/
    var data = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    };


    Cliente.getAllForVentas(data).success(function (data) {
      vm.clientes = data.clientes;
    });


    /************* FIN LLENAR LOS COMBOS ********/



    //*********** ENVIAR EL FORMULARIO

    vm.guardarVenta = function () {
      //guardar la venta

      vm.formValid = false;


      var record = {};
      record.suscriptor_id = $rootScope.suscriptor.id;
      record.valor = parseInt(vm.venta.valor);
      record.tasa = parseFloat(vm.venta.tasa);
      record.neta = parseFloat(vm.venta.neta);
      record.comision = parseFloat(record.valor * record.tasa);
      record.saldo_anterior = parseInt(vm.venta.valor);
      record.nuevo_saldo = parseInt(vm.venta.valor);
      record.reversada = false;
      record.automatica = 0;
      record.proveedor_id = vm.venta.proveedor.id;
      record.tasa_compra = vm.venta.proveedor.tasa_compra;
      record.user_id = $rootScope.currentUser.id;
      record.cliente_id = vm.selectedCliente.id;


      Proveedor.find(vm.venta.proveedor.id).then(function (data) {
        vm.proveedor = data.proveedor;
        var saldo_proveedor = parseInt(vm.proveedor.saldo);
        var neta = parseInt(vm.venta.neta);
        var nuevo_saldo = (saldo_proveedor - neta);
        vm.saldo_proveedor = nuevo_saldo;
      });

      record.saldo_proveedor = vm.saldo_proveedor;


      $scope.promise = Venta.create(record)
        .then(function (response) {
          $scope.venta = response.venta;
          $scope.venta_id = response.id;
          vm.proveedor_id = response.venta.proveedor_id;

          //actualizar el saldo del proveedor
          var dataProveedor = {
            saldo: record.saldo_proveedor
          };

          Proveedor.update(record.proveedor_id, dataProveedor).then(function (response) {
            //guardar el evento
            var evento = {};
            evento.evento = 'VENTA REGISTRADA';
            evento.numero = $scope.venta_id;
            evento.valor = vm.venta.valor;
            evento.saldo = dataProveedor.saldo;
            evento.cliente_id = record.cliente_id;
            evento.user_id = $rootScope.currentUser.id;
            evento.suscriptor_id = $rootScope.suscriptor.id;
            evento.tipo = '-';
            evento.proveedor_id = vm.proveedor_id;


            //GUARDA EL EVENTO
            Evento.create(evento).then(function (result) {
              vm.ventaCreada = true;
              vm.saldo_proveedor = dataProveedor.saldo;
              $timeout(function () {
                $('#cliente_value').focus();
                $('#cliente_value').val('');
                //vm.selectedCliente = null;
                vm.venta = {};
                vm.ventaCreada = false;

              }, 500)
            })
          });
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  }]);
