app.factory('Venta', ['$http', '$q',
  function ($http, $q) {
    
    return {
      getAll: function (data) {
        return $http.get('/api/ventas/', {params: data});
      },
      findTransaccion: function (suscriptor_id, transaccion) {
        var defered = $q.defer();
        
        $http.get('/api/venta/suscriptor/' + suscriptor_id + '/transaccion/' + transaccion)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
      },
      get: function (id, data) {
        var defered = $q.defer();
        $http.get('/api/ventas/' + id, {params: data})
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
        
      },
      create: function (data) {
        var defered = $q.defer();
        $http.post('/api/ventas', data)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
        
      }
      ,
      update: function (id, data) {
        var defered = $q.defer();
        
        $http.put('/api/ventas/' + id, data)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          })
        
        return defered.promise;
      },
      
      getVentasByProveedor: function (proveedor_id) {
        var defered = $q.defer();
        $http.get('/api/venta/proveedor/' + proveedor_id)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
      }, getVentasByRecaudador: function (recaudador_id) {
        var defered = $q.defer();
        $http.get('/api/venta/recaudador/' + recaudador_id)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
      },
      getVentasByCliente: function (data) {
        var defered = $q.defer();
        $http.get('/api/venta/cliente', {'params': data})
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
      }
    };
  }]);
