app.controller('EventosCtrl', ['$rootScope', '$scope', 'Evento', '$uibModal',
  function ($rootScope, $scope, Evento, $uibModal) {
    $rootScope.page_title = 'Eventos';
    var vm = $scope;
    vm.dataInvalid = false;
    
    /******* CONFIGURACION DE LAS FECHA ********/
    $scope.today = function () {
      $scope.dt1 = new Date();
      $scope.dt2 = new Date();
    };
    $scope.today();
    
    $scope.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    
    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
    
    $scope.open2 = function () {
      $scope.popup2.opened = true;
    };
    
    $scope.popup1 = {
      opened: false
    };
    
    $scope.popup2 = {
      opened: false
    };
    
    $scope.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    }
    
    vm.buscar = function () {
      vm.getEventos();
    };
    
    vm.total = function (val1, val2) {
      return parseInt(val1) + parseInt(val2);
    }
    
    vm.eventos = [];
    $scope.pageSize = 20;
    $scope.currentPage = 1;
    $scope.maxSize = 10;
    
    
    $scope.sortKey = 'nombre';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    vm.getEventos = function () {
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD'),
      };
      vm.promise = Evento.getAll(data).success(function (response) {
        $scope.eventos = response.eventos;
      });
    }
    
    vm.getEventos();
    
    
    $scope.open = function (evento) {
      $scope.evento = evento;
      
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/eventos/form.html',
        controller: 'EventoModalCtrl',
        resolve: {
          evento: function () {
            return $scope.evento;
          }
        }
      });
      
    }; //END FUNCTION OPEN
    
  }]);


app.controller('EventoModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'evento',
  function ($rootScope, $scope, $uibModalInstance, evento) {
    var vm = $scope;
    vm.evento = evento;
    
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      $uibModalInstance.close(vm.consignacion);
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    
  }]);
