app.controller('ChangePasswordCtrl', ['$rootScope', '$scope', 'Usuario', '$state',
  function ($rootScope, $scope, Usuario, $state) {
    $rootScope.page_title = "Cambio de contraseña";
    
    var vm = $scope;
    vm.failValidation = false;
    vm.successValidation = false;
    vm.user = $rootScope.currentUser;
    
    
    vm.cambiarPassword = function () {
      //validar que las contraseña escritas coicidan
      if (vm.pass1 === vm.pass2) {
        var params = {
          password: vm.pass1
        };
        vm.promise = Usuario.update(vm.user.id, params).then(function (data) {
          vm.successValidation = true;
          vm.failValidation = false;
          setTimeout(function () {
            $state.go('dashboard');
          }, 1000);
        });
      } else {
        vm.failValidation = true;
      }
    }
    
    
    vm.resetAlert = function () {
      vm.failValidation = false;
    }
    
    
  }]);

