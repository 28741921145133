
  app.factory('Recaudo', ['$http','$q',function ($http, $q) {
    
    return {
      getAll: function (data) {
        var defered = $q.defer();
        $http.get('/api/recaudos/', {params: data})
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
        
      },
      
      get: function (id, data) {
        return $http.get('/api/recaudos/' + id, {params: data});
      },
      
      create: function (data) {
        var defered = $q.defer();
        $http.post('/api/recaudos', data)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          })
        
        return defered.promise;
      }
      ,
      
      update: function (id, data) {
        var defered = $q.defer();
        
        $http.put('/api/recaudos/' + id, data)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          })
        
        return defered.promise;
        
      },
      delete: function (id) {
        var defered = $q.defer();
        
        $http.delete('/api/recaudos/' + id)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          })
        
        return defered.promise;
        
      },
      getClientes: function (suscriptor_id, recaudador_id) {
        var defered = $q.defer();
        
        $http.get('/api/recaudo_clientes/suscriptor/' + suscriptor_id + '/recaudador/' + recaudador_id)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          });
        
        return defered.promise;
      },
      getVentas: function (cliente_id) {
        var defered = $q.defer();
        
        $http.get('/api/recaudo_ventas/' + cliente_id)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (error) {
            defered.reject(error);
          });
        
        return defered.promise;
      }
      
    }
  }]);
;