
  app.controller('UsuariosCtrl', ['$rootScope', '$scope', 'Usuario', 'toastr', '$uibModal', '$state', '$confirm',
    function ($rootScope, $scope, Usuario, toastr, $uibModal, $state, $confirm) {
      $rootScope.page_title = "Administración de Usuarios del sistema";
      
      var vm = $scope;
      vm.usuarios = [];
      vm.usuario = {};
      
      vm.adminUsers = false;
      vm.usuarioCreado = false;
      vm.alert_strong = '';
      vm.alert_msg = '';
      
      //funcion para validar si un usuario puede registrar descuentoes
      var validarPermisoUsers = function () {
        angular.forEach($rootScope.menu_main, function (permiso) {
          if (permiso.id == 4) {
            vm.adminUsers = true;
          }
        });
      };
      
      validarPermisoUsers();
      
      if (!vm.adminUsers) {
        toastr.warning('No tienes permiso para realizar esta acción!', '', {
          closeButton: true
        });
        $state.go('dashboard');
      }
      
      
      //metodo para buscar todos los usuarios
      vm.getUsuarios = function () {
        vm.promise = Usuario.getAll()
          .then(function (data) {
            vm.usuarios = data.users;
            
          })
          .catch(function (error) {
            toastr.error(error, 'Error', {
              closeButton: true
            });
          })
      }; //end getUsuarios
      
      vm.getUsuarios();
      
      
      $scope.open = function () {
        vm.usuarioCreado = false;
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'templates/usuarios/form.html',
          controller: 'UsuariosModalCtrl',
          resolve: {
            usuario: function () {
              return vm.usuario;
            }
          }
        });
        
        modalInstance.result.then(function (usuario) {
          var record = {};
          record.suscriptor_id = $rootScope.suscriptor.id;
          record.name = usuario.name;
          record.email = usuario.email;
          record.cargo = usuario.cargo;
          record.direccion = usuario.direccion;
          record.telefono = usuario.telefono;
          
          if (usuario.id == null) {
            //es un nuevo usuario
            vm.promise = Usuario.create(record).then(function (data) {
              vm.usuario = data.user;
              vm.usuario.id = data.id;
              vm.usuario.estado = true;
              vm.usuario.admin = false;
              vm.usuarios.push(vm.usuario);
              vm.usuarioCreado = true;
              vm.alert_strong = 'Felicidades. Usuario creado correctamente!. Password:';
              vm.alert_msg = data.password;
              vm.usuario = {};
            })
              .catch(function (error) {
                console.log("ERROR CREATE AL USUARIO", error)
              });
          } else {
            //se esta editando el usuario
            record.admin = vm.usuario.admin;
            record.estado = vm.usuario.estado;
            vm.promise = Usuario.update(usuario.id, record).then(function (data) {
              vm.usuario = data.user;
              vm.usuario = {};
              toastr.success('Usuario actualizado exitosamente!', 'Ok', {
                closeButton: true
              });
            })
              .catch(function (error) {
                console.log("ERROR UPDATE AL USUARIO", error)
              });
          }
          
          
        }, function () {
          vm.usuario = {};
        });
      }; //END FUNCTION OPEN
      
      
      //metodo para ocultar la alerta
      vm.hidMsg = function () {
        vm.usuarioCreado = false;
        vm.alert_strong = '';
        vm.alert_msg = '';
      };
      
      //metodo para editar un usuario
      vm.editar = function (user) {
        vm.usuario = user;
        vm.usuario.estado = user.estado == '1' ? true : false;
        vm.usuario.admin = user.admin == '1' ? true : false;
        $scope.open();
      };
      
      
      vm.resetPassword = function (user) {
        vm.usuario = user;
        var title = "Generar nueva contraseña";
        var texto = "Estas seguro de resetear el password del usuario: " + user.name + "?";
        vm.proveedor = {};
        $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
          .then(function () {
            var params = {
              reset: true
            };
            vm.promise = Usuario.update(user.id, params).then(function (data) {
              vm.usuario = data.user;
              vm.usuario = {};
              toastr.success('Password generado exitosamente!', 'Ok', {
                closeButton: true
              });
              
              vm.usuarioCreado = true;
              vm.alert_strong = 'Felicidades. Nuevo Password';
              vm.alert_msg = data.password;
            });
            
            
          });
      };
      
      
      //metodo para asignar los permisos
      vm.setPermisos = function (user) {
        vm.usuario = user;
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'templates/usuarios/permisos.html',
          controller: 'PermisosModalCtrl',
          resolve: {
            usuario: function () {
              return vm.usuario;
            }
          }
        });
        
        modalInstance.result.then(function (usuario) {
          
        }), function () {
          vm.usuario = {};
        };
        
        
      }
      
      
    }]);
  
  //controlador para el modal del usuario
  
  
  app.controller('UsuariosModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'usuario',
    function ($rootScope, $scope, $uibModalInstance, usuario) {
      var vm = $scope;
      vm.usuario = usuario;
      if (vm.usuario.id != null) {
        vm.title = "Editando al usuario: " + usuario.name;
        vm.isNew = false;
      } else {
        vm.title = "Registrar nuevo usuario";
        vm.isNew = true;
      }
      
      
      vm.ok = function () {
        $uibModalInstance.close(vm.usuario);
      };
      
      vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      
      
    }]);
  
  
  //controlador para el modal de permisos
  app.controller('PermisosModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'usuario', 'Usuario', 'toastr',
    function ($rootScope, $scope, $uibModalInstance, usuario, Usuario, toastr) {
      var vm = $scope;
      vm.usuario = usuario;
      
      //buscar el usuario
      var userPromise = Usuario.get(usuario.id).then(function (data) {
        vm.usuario = data.user;
        angular.forEach(vm.usuario.menu, function (menu) {
          switch (menu.id) {
            case 1:
              //proveedores
              vm.p1 = true;
              break;
            case 2:
              //consignaciones
              vm.p2 = true;
              break;
            case 3:
              //Clientes
              vm.p3 = true;
              break;
            case 4:
              //Usuarios
              vm.p4 = true;
              break;
            case 5:
              //Recaudadores
              vm.p5 = true;
              break;
            case 6:
              //Zonas
              vm.p6 = true;
              break;
            case 7:
              //Reportes
              vm.p7 = true;
              break;
            case 8:
              //bancos
              vm.p8 = true;
              break;
            case 9:
              //ventas
              vm.p9 = true;
              break;
            case 10:
              //recaudos
              vm.p10 = true;
              break;
            case 11:
              //cargar archivos megared
              vm.p11 = true;
              break;
            case 12:
              //eventos
              vm.p12 = true;
              break;
            case 13:
              //tarjetas
              vm.p13 = true;
              break;
            case 14:
              //comisiones
              vm.p14 = true;
              break;
            case 15:
              //descuentos
              vm.p15 = true;
              break;
            case 21:
              //reversar consignaciones
              vm.p21 = true;
              break;
            case 91:
              //reversar ventas
              vm.p91 = true;
              break;
            case 101:
              //reversar recaudo
              vm.p101 = true;
              break;
            case 16:
              //reversar recaudo
              vm.p16 = true;
              break;
            case 17:
              //cartera
              vm.p17 = true;
              break;
            
          }
        });
      });
      
      
      if (vm.usuario.id != null) {
        vm.title = "Editando al usuario: " + usuario.name;
        vm.isNew = false;
      } else {
        vm.title = "Registrar nuevo usuario";
        vm.isNew = true;
      }
      
      
      vm.setPermiso = function (model, menu) {
        var params = {
          user_id: vm.usuario.id,
          menu_id: menu,
          action: model === true ? 1 : 0
          
        }
        vm.promise = Usuario.setPermiso(params).then(function (result) {
          toastr.success(result.msg, 'Aviso!', {
            closeButton: true
          });
        });
        
      };
      
      
      vm.ok = function () {
        $uibModalInstance.close(vm.usuario);
      };
      
      vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      
      
    }]);
  
