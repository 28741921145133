app.controller('DashboardCtrl', ['$rootScope', '$scope', 'Proveedor', 'Venta',
  function ($rootScope, $scope, Proveedor, Venta) {
    var vm = $scope;
    $rootScope.page_title = 'Panel de control';
    
    
    vm.sortKey = 'nombre';
    
    vm.proveedores = [];
    
    
    //1. Traer la lista de los proveedores activos
    var params = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    };
    
    
    vm.promise = Proveedor.getActivos(params).then(function (data) {
      angular.forEach(data.proveedores, function (p) {
        Venta.getVentasByProveedor(p.id).then(function (result) {
          p.venta = result.venta;
          vm.proveedores.push(p);
        })
      });
      
      
    });
    
    
  }]);

