app.controller('TarjetasCtrl', ['$rootScope', '$scope', 'Tarjeta', '$uibModal', 'toastr',
  function ($rootScope, $scope, Tarjeta, $uibModal, toastr) {
    $rootScope.page_title = 'Tarjetas de recaudo';
    var vm = $scope;
    
    vm.tarjetas = [];
    $scope.pageSize = 20;
    $scope.currentPage = 1;
    $scope.maxSize = 10;
    
    
    $scope.sortKey = 'numero';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    vm.getTarjetas = function () {
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
      };
      vm.promise = Tarjeta.getAll(data).success(function (response) {
        $scope.tarjetas = response.tarjetas;
      });
    }
    
    vm.getTarjetas();
    
    
    $scope.tarjeta = {};
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/tarjetas/form.html',
        controller: 'TarjetaModalCtrl',
        size: size,
        resolve: {
          tarjeta: function () {
            return $scope.tarjeta;
          }
        }
      });
      
      modalInstance.result.then(function (tarjeta) {
        
        if (!tarjeta.id) {
          //si no existe el tarjeta
          var record = $scope.tarjeta;
          record.suscriptor_id = $rootScope.suscriptor.id;
          record.numero = ("00000" + vm.tarjeta.numero).slice(-6);
          record.cliente_id = vm.tarjeta.cliente.id;
          record.proveedor_id = vm.tarjeta.proveedor.id;
          record.estado = 1;
          record.fecha = new Date();
          
          $scope.promise = Tarjeta.create(record).then(function (response) {
            toastr.info('Good!', 'Tarjeta registrada exitosamente!');
            vm.tarjetas.push(record);
            //vm.getTarjetas();
          }, function (error) {
            console.log(error);
            toastr.error('Error', error.data.error.numero[0]);
          }).$promise;
        } else {
          var record = $scope.tarjeta;
          record.numero = ("00000" + vm.tarjeta.numero).slice(-6);
          record.cliente_id = vm.tarjeta.cliente.id;
          record.proveedor_id = vm.tarjeta.proveedor.id;
          record.estado = vm.tarjeta.estado;
          
          Tarjeta.update($scope.tarjeta.id, record).success(function (response) {
            toastr.info("Tarjeta actualizada correctamente!", 'info', {
              closeButton: true
            });
            $scope.tarjeta = {};
          }).error(function (error) {
            toastr.error("Email o Codigo duplicados", error.data.error.numero[0], {
              closeButton: true
            });
            vm.open();
            
          })
          
          
        }
      }, function () {
        $scope.tarjeta = {};
      });
    }; //END FUNCTION OPEN
    
    vm.edit = function (tarjeta) {
      $scope.tarjeta = tarjeta;
      vm.tarjeta.estado = tarjeta.estado == '1' ? true : false;
      $scope.open();
    };
    
  }]);


app.controller('TarjetaModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'tarjeta', 'Proveedor', 'Cliente',
  function ($rootScope, $scope, $uibModalInstance, tarjeta, Proveedor, Cliente) {
    var vm = $scope;
    vm.tarjeta = tarjeta;
    
    
    /************* LLENAR LOS COMBOS ********/
    var data = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    }
    
    Proveedor.getActivos(data).then(function (data) {
      vm.proveedores = data.proveedores;
    });
    
    var data_clientes = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true,
      tipo_cliente_id: 2
    }
    Cliente.getAll(data_clientes).success(function (data) {
      vm.clientes = data.clientes;
    });
    
    if (!vm.tarjeta.id) {
      vm.isnew = true;
      vm.title = "Registrar nueva tarjeta";
    } else {
      vm.title = "Editar tarjeta de recaudo: " + vm.tarjeta.numero;
      vm.isnew = false;
    }
    
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      $uibModalInstance.close(vm.tarjeta);
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    
  }]);
