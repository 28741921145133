
  app.factory('Comision', ['$http','$q',function ($http, $q) {
    
    return {
      getAll: function (data) {
        var defered = $q.defer();
        $http.get('/api/commissions/', {params: data})
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        
        return defered.promise;
      },
      create: function (data) {
        var defered = $q.defer();
        $http.post('/api/commissions/', data)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err)
          });
        return defered.promise;
      }
      
    }
  }]);

