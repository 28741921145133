app.factory('Ciudad', ['$http',function ($http) {
  
  return {
    find: function (id) {
      return $http.get('/api/ciudades/' + id);
    }
  }
  
  
}]);
