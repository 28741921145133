app.controller('ProveedoresCtrl', ['$rootScope', '$scope', 'Proveedor', '$uibModal', 'toastr', 'Pagination',
  function ($rootScope, $scope, Proveedor, $uibModal, toastr, Pagination) {
    $rootScope.page_title = 'Proveedores';
    var vm = $scope;
    vm.pagination = Pagination.getNew(10);
    
    vm.totalItems = 0;
    
    
    $scope.sortKey = 'nombre';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    function getProveedores() {
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
      };
      vm.promise = Proveedor.getAll(data).then(function (data) {
        $scope.proveedores = data.proveedores;
        $scope.totalItems = data.total;
        $scope.pagination.numPages = Math.ceil($scope.proveedores.length / $scope.pagination.perPage);
      });
    };
    
    getProveedores();
    
    $scope.proveedor = {};
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/proveedores/form.html',
        controller: 'ProveedorModalCtrl',
        size: size,
        resolve: {
          proveedor: function () {
            return $scope.proveedor;
          }
        }
      });
      
      modalInstance.result.then(function (proveedor) {
        //$scope.proveedor = res;
        
        if (!proveedor.id) {
          //si no existe el proveedor
          var record = $scope.proveedor;
          record.suscriptor_id = $rootScope.suscriptor.id;
          $scope.promise = Proveedor.create(record).then(function (response) {
            toastr.info('Good!', 'Proveedor registrado exitosamente!');
            vm.getResultPage();
          }, function (error) {
            toastr.error('Error', 'No se pudo guardar la informacion');
          }).$promise;
        } else {
          var record = $scope.proveedor;
          Proveedor.update($scope.proveedor.id, record).then(function (response) {
            toastr.info("proveedor actualizado correctamente!", 'info', {
              closeButton: true
            });
            $scope.proveedor = {};
          });
        }
      }, function () {
        $scope.proveedor = {};
        //$log.info('Modal dismissed at: ' + new Date());
        getProveedores();
        
      });
    }; //END FUNCTION OPEN
    
    vm.edit = function (proveedor) {
      $scope.proveedor = proveedor;
      vm.proveedor.estado = proveedor.estado == '1' ? true : false;
      $scope.open();
    };
    
    
  }]);


app.controller('ProveedorModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'proveedor',
  function ($rootScope, $scope, $uibModalInstance, proveedor) {
    var vm = $scope;
    
    vm.proveedor = proveedor;
    
    
    vm.showCheckboxActive = false;
    $scope.userIsAdmin = $rootScope.currentUser.admin;
    
    vm.title = "";
    
    //************************CREAR EL FORMULARIO
    
    if (!vm.proveedor.id) {
      vm.showCheckboxActive = false;
      vm.proveedor.saldo = 0;
      vm.title = "Registrar nuevo proveedor!";
    } else {
      vm.title = "Editando a: " + vm.proveedor.nombre + "!";
      vm.showCheckboxActive = true;
    }
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      if (vm.proveedorForm.$valid) {
        $uibModalInstance.close(vm.proveedor);
      }
      
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
  }]);
