app.factory('Proveedor', ['$http', '$q', function ($http, $q) {
  
  return {
    
    getAll: function (data) {
      var defered = $q.defer();
      
      $http.get('/api/proveedores/', {params: data})
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
    },
    create: function (data) {
      return $http.post('/api/proveedores', data);
    }
    ,
    update: function (id, data) {
      var defered = $q.defer();
      
      $http.put('/api/proveedores/' + id, data)
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
    }
    ,
    find: function (id) {
      var defered = $q.defer();
      
      $http.get('/api/proveedores/' + id)
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
      
      
    },
    getActivos: function (data) {
      var defered = $q.defer();
      
      $http.get('/api/proactivos/', {params: data})
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
    }
    
  };
}]);
