app.controller('ComisionesCtrl', ['$rootScope', '$scope', 'Comision', 'toastr', 'Evento', 'Proveedor', '$uibModal', '$state',
  function ($rootScope, $scope, Comision, toastr, Evento, Proveedor, $uibModal, $state) {
    $rootScope.page_title = 'Comisiones de terceros';
    var vm = $scope;
    vm.registrarComisionAction = false;
    
    //funcion para validar si un usuario puede registrar comisiones
    var validarPermisoRegistrarComision = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id === 14) {
          vm.registrarComisionAction = true;
        }
      });
    };
    
    validarPermisoRegistrarComision();
    
    
    if (!vm.registrarComisionAction) {
      toastr.warning('No tienes permiso para realizar esta acción!');
      $state.go('dashboard');
    }
    
    
    vm.comision = {};
    $rootScope.proveedores = [];
    vm.comisiones = [];
    
    /******* CONFIGURACION DE LAS FECHA ********/
    vm.today = function () {
      vm.dt1 = new Date();
      vm.dt2 = new Date();
    };
    vm.today();
    
    vm.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.open1 = function () {
      vm.popup1.opened = true;
    };
    
    vm.open2 = function () {
      vm.popup2.opened = true;
    };
    
    vm.popup1 = {
      opened: false
    };
    
    vm.popup2 = {
      opened: false
    };
    
    //obtener lista de los recaudadores
    vm.getProveedores = function () {
      vm.promise = Proveedor.getAll({suscriptor_id: $rootScope.suscriptor.id})
        .then(function (data) {
          $rootScope.proveedores = data.proveedores;
        });
    };
    
    vm.getProveedores();
    
    vm.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    };
    
    
    vm.pageSize = 20;
    vm.currentPage = 1;
    vm.maxSize = 10;
    
    
    vm.sortKey = 'id';
    vm.criterio = '';
    
    vm.sort = function (keyname) {
      vm.sortKey = keyname;   //set the sortKey to the param passed
      vm.reverse = !vm.reverse; //if true make it false and vice versa
    };
    
    
    //*********************************************** METODO GETVENTAS********
    vm.getComisionesByProveedor = function (proveedor) {
      vm.criterio = '';
      var params = {
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD'),
        proveedor_id: proveedor.id
      };
      
      vm.promise = Comision.getAll(params)
        .then(function (data) {
          vm.comisiones = data.comisiones;
        }).catch(function (data, status) {
          toastr.error('Se ha presentado un error de comunicacion con el servidor.', 'Error');
          return status;
        });
    };
    
    
    vm.getAllComisiones = function () {
      vm.proveedor = -1;
      vm.criterio = '';
      var params = {
        suscriptor_id: $rootScope.suscriptor.id,
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD')
      };
      vm.promise = Comision.getAll(params).then(function (data) {
        vm.comisiones = data.comisiones;
      }).catch(function (data, status) {
        toastr.error('Se ha presentado un error de comunicacion con el servidor.', 'Error');
        
        return status;
      });
      
      
    };
    
    //buscar los recaudos
    
    vm.getAllComisiones();
    
    
    //******** funcion para sumar el total de las ventas ****************
    vm.$watchCollection('filterList', function (list) {
      vm.valores = 0;
      angular.forEach(list, function (value) {
        vm.valores = vm.valores + parseFloat(value.valor);
      });
    });
    
    //****************************************OPEN MODAL**********************************************
    $scope.open = function () {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/tools/addComision.html',
        controller: 'addComisionModalCtrl',
        resolve: {
          comision: function () {
            return vm.comision;
          }
        }
      });
      
      modalInstance.result.then(function (comision) {
        var record = {};
        vm.comision = comision;
        record.proveedor_id = comision.proveedor.id;
        record.user_id = $rootScope.currentUser.id;
        record.suscriptor_id = $rootScope.suscriptor.id;
        record.valor = parseFloat(comision.valor);
        record.nota = comision.nota;
        
        vm.promise = Comision.create(record).then(function (data) {
          vm.comision.id = data.id;
          vm.comision.user = $rootScope.currentUser;
          vm.comision.created_at = moment(new Date(), 'YYYY-MM-DD');
          vm.comisiones.push(vm.comision);
          var idComision = data.id;
          //actualizar el saldo del proveedor
          var saldo = parseFloat(comision.proveedor.saldo);
          var nuevo_saldo = (saldo + parseFloat(comision.valor));
          var params = {
            saldo: nuevo_saldo
          };
          vm.promise = Proveedor.update(comision.proveedor.id, params)
            .then(function (data) {
              var evento = {};
              evento.evento = 'COMISION REGISTRADA';
              evento.numero = idComision;
              evento.proveedor_id = comision.proveedor.id;
              evento.valor = comision.valor;
              evento.saldo = nuevo_saldo;
              evento.user_id = $rootScope.currentUser.id;
              evento.suscriptor_id = $rootScope.suscriptor.id;
              evento.tipo = '+';
              
              
              //GUARDA EL EVENTO
              vm.promise = Evento.create(evento).then(function (result) {
                toastr.info('Good!', 'Comision registrada exitosamente!');
                vm.comision = {};
              })
            });
        })
          .catch(function (error) {
            console.log("ERROR CREATE COMISION", error)
          })
        
        
      }, function () {
        vm.comision = {};
        
      });
    }; //END FUNCTION OPEN
    
  }]); //END CONTROLLER 1

app.controller('addComisionModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'comision', 'Proveedor',
  function ($rootScope, $scope, $uibModalInstance, comision, Proveedor) {
    var vm = $scope;
    vm.comision = comision;
    //obtener lista de los recaudadores
    vm.getProveedores = function () {
      vm.promise = Proveedor.getAll({suscriptor_id: $rootScope.suscriptor.id})
        .then(function (data) {
          vm.proveedores = data.proveedores;
        });
    };
    
    vm.getProveedores();
    
    
    vm.ok = function () {
      $uibModalInstance.close(vm.comision);
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    
  }]);

