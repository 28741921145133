app.controller('RecaudosAddCtrl', ['$rootScope', '$scope', 'Recaudo', 'Venta', 'toastr', 'Recaudador', 'filterFilter',
  'Evento',
  function ($rootScope, $scope, Recaudo, Venta, toastr, Recaudador, filterFilter, Evento) {
    $rootScope.page_title = 'Recaudos';
    
    var vm = $scope;
    vm.venta = {};
    vm.recaudador = {};
    vm.venta_id = null;
    vm.valor_aplicar_valid = false;
    vm.recaudadores = [];
    vm.clientes = [];
    vm.ventas = [];
    vm.oneAtATime = true;
    vm.isBusy = false;
    
    
    $('#venta_id').focus();
    
    vm.limpiarCampos = function () {
      vm.venta = {};
      vm.valor_aplicar = null;
    };
    
    
    //obtener lista de los recaudadores
    vm.getRecaudadores = function () {
      vm.promise = Recaudador.getAll({suscriptor_id: $rootScope.suscriptor.id})
        .then(function (data) {
          if ($rootScope.currentUser.admin === 1) {
            vm.recaudadores = data.recaudadores;
          } else {
            var obj = {'visible_for_users': 1}
            vm.recaudadores = filterFilter(data.recaudadores, obj);
          }
          
        });
    };
    
    vm.getRecaudadores();
    
    
    //metodo para actualizar elscope con la venta ya seleccioanda
    vm.selectVenta = function (venta) {
      vm.venta = venta;
      vm.valor_aplicar = vm.venta.nuevo_saldo;
      vm.cliente_id = vm.venta.cliente.id;
      //vm.valor_aplicar_valid = true;
      setTimeout(function () {
        $('#valor_aplicar').focus().select();
        
      }, 10);
    };
    
    
    vm.buscarVenta = function (venta_id) {
      vm.venta_id = venta_id;
      vm.promise = Venta.get(vm.venta_id).then(function (data) {
        vm.venta = data.venta;
        vm.valor_aplicar = vm.venta.nuevo_saldo;
        //vm.valor_aplicar_valid = true;
        
        //validar si el recaudador seleccionado es el mismo de la zona del la venta
        if (vm.recaudador.id === vm.venta.cliente.zona.recaudador.id) {
          setTimeout(function () {
            $('#valor_aplicar').focus().select();
            
          }, 10);
        } else {
          toastr.warning('Advertencia', 'El recaudador seleccioanado no es el mismo que el de la venta');
          vm.valor_aplicar = null;
          vm.valor_aplicar_valid = false;
          $("#venta_id").focus();
        }
        
        
      }).catch(function (error) {
        toastr.error('', 'La venta no se encuentra registrada!');
      })
    } // end buscar venta
    
    vm.validarValorAplicar = function ($event) {
      $event.preventDefault();
      vm.valor_aplicar_valid = false;
      
      
      //calcular el saldo de la venta
      vm.recaudo_nuevo_saldo = (vm.venta.nuevo_saldo - vm.valor_aplicar);
      
      if (vm.recaudo_nuevo_saldo >= 0) {
        var keyCode = $event.which || $event.keyCode;
        if (keyCode === 13) {
          vm.valor_aplicar_valid = true;
          setTimeout(function () {
            $('#btnGuardar').focus().select();
            
          }, 100)
        }
      }
      
      
    }//end validar valor_aplicar
    
    //metodo para guardar el recaudo
    vm.save = function () {
      var params = {
        valor: vm.valor_aplicar,
        user_id: $rootScope.currentUser.id,
        suscriptor_id: $rootScope.suscriptor.id,
        venta_id: vm.venta.id,
        recaudador_id: vm.recaudador.id,
        saldo_venta: vm.recaudo_nuevo_saldo,
        saldo_anterior: vm.venta.nuevo_saldo,
      };
      vm.promise = Recaudo.create(params)
        .then(function (response) {
          vm.recaudo = response.recaudo;
          //actualizar la venta
          var ventaparams = {
            saldo_anterior: vm.venta.nuevo_saldo,
            nuevo_saldo: (vm.venta.nuevo_saldo - vm.valor_aplicar)
          };
          
          Venta.update(vm.venta.id, ventaparams)
            .then(function (response) {
              //guardar el ecento
              var e = {};
              e.evento = 'RECAUDO APLICADO';
              e.numero = vm.recaudo.id;
              e.valor = vm.recaudo.valor;
              e.saldo = ventaparams.nuevo_saldo;
              e.tipo = "*";
              e.user_id = $rootScope.currentUser.id;
              e.suscriptor_id = $rootScope.suscriptor.id;
              e.proveedor_id = vm.venta.proveedor.id;
              e.cliente_id = vm.venta.cliente.id;
              Evento.create(e)
                .then(function (data) {
                  toastr.info('', 'Recaudo aplicado correctamente!');
                  vm.clearForm();
                  vm.ventasByCliente(vm.cliente_id);
                })
                .catch(function (error) {
                  toastr.error('', 'No se pudo guardar el recaudo!');
                })
              
            });
          
        })
        .catch(function (error) {
          
        });
    }//end save
    
    
    //metodo para cargar la lista de clientes cuando cambie el combo de recaudadores
    vm.cboRecaudadoresChange = function () {
      if (vm.recaudador !== null) {
        getClientes(vm.recaudador.id);
      }
      vm.ventas = [];
      vm.venta = {};
      vm.venta_id = null;
    };
    
    
    //clearform
    vm.clearForm = function () {
      vm.venta = {};
      vm.venta_id = null;
      vm.valor_aplicar = null;
      vm.valor_aplicar_valid = false;
      vm.recaudo_nuevo_saldo = null;
      $("#venta_id").focus();
    };
    
    
    //metodo para buscar todos los clientes de la zona del recaudador seleted
    var getClientes = function (recaudador_id) {
      vm.valor_aplicar_valid = false;
      var params = {
        suscriptor_id: $rootScope.suscriptor.id,
        recaudador_id: recaudador_id
      }
      
      vm.promise = Recaudo.getClientes(params.suscriptor_id, params.recaudador_id)
        .then(function (data) {
          vm.clientes = data.clientes;
        }).catch(function (error) {
          vm.clientes = [];
        });
      
    };
    
    
    //metodo para buscar las ventas del cliente seleccionado
    vm.ventasByCliente = function (cliente_id) {
      vm.isBusy = true;
      Recaudo.getVentas(cliente_id)
        .then(function (data) {
          vm.ventas = data.ventas;
          vm.isBusy = false;
        })
        .catch(function (error) {
          toastr.error('Error al cargar las ventas!');
        });
    }
    
  }]);
  
  

