app.controller('RecaudadoresCtrl', ['$rootScope', '$scope', 'Recaudador', '$uibModal', 'toastr', 'Pagination',
  function ($rootScope, $scope, Recaudador, $uibModal, toastr, Pagination) {
    $rootScope.page_title = 'Recaudadores';
    var vm = $scope;
    vm.pagination = Pagination.getNew(10);
    
    vm.totalItems = 0;
    
    
    $scope.sortKey = 'nombre';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    function getRecaudadores() {
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
      };
      vm.promise = Recaudador.getAll(data).then(function (data) {
        console.log(data);
        $scope.recaudadores = data.recaudadores;
        $scope.totalItems = data.total;
        $scope.pagination.numPages = Math.ceil($scope.recaudadores.length / $scope.pagination.perPage);
      });
    };
    
    getRecaudadores();
    
    $scope.recaudador = {};
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/recaudadores/form.html',
        controller: 'RecaudadorModalCtrl',
        size: size,
        resolve: {
          recaudador: function () {
            return $scope.recaudador;
          }
        }
      });
      
      modalInstance.result.then(function (recaudador) {
        //$scope.recaudador = res;
        
        if (!recaudador.id) {
          //si no existe el recaudador
          var record = $scope.recaudador;
          record.suscriptor_id = $rootScope.suscriptor.id;
          $scope.promise = Recaudador.create(record).then(function (response) {
            toastr.info('Good!', 'Recaudador registrado exitosamente!');
            getRecaudadores();
          }, function (error) {
            toastr.error('Error', 'No se pudo guardar la informacion');
          }).$promise;
        } else {
          var record = $scope.recaudador;
          Recaudador.update($scope.recaudador.id, record).then(function (response) {
            toastr.info("recaudador actualizado correctamente!", 'info', {
              closeButton: true
            });
            $scope.recaudador = {};
          });
        }
      }, function () {
        $scope.recaudador = {};
        //$log.info('Modal dismissed at: ' + new Date());
        getRecaudadores();
        
      });
    }; //END FUNCTION OPEN
    
    vm.edit = function (recaudador) {
      $scope.recaudador = recaudador;
      vm.recaudador.estado = recaudador.estado == '1' ? true : false;
      vm.recaudador.visible_for_users = recaudador.visible_for_users == '1' ? true : false;
      $scope.open();
    };
    
    
  }]);


app.controller('RecaudadorModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'recaudador',
  function ($rootScope, $scope, $uibModalInstance, recaudador) {
    var vm = $scope;
    
    $scope.userIsAdmin = $rootScope.currentUser.admin;
    
    vm.title = "";
    
    //************************CREAR EL FORMULARIO
    vm.recaudador = recaudador;
    
    if (!vm.recaudador.id) {
      vm.isnew = true;
      vm.recaudador.saldo = 0;
      vm.title = "Registrar nuevo recaudador!";
    } else {
      vm.title = "Editando a: " + vm.recaudador.nombre + "!";
      vm.isnew = false;
    }
    
    //*********** ENVIAR EL FORMULARIO
    
    vm.ok = function () {
      if (vm.recaudadorForm.$valid) {
        $uibModalInstance.close(vm.recaudador);
      }
      
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
  }]);
