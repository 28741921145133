app.controller('AuthController', ['$auth', '$state', '$http', '$rootScope', 'LocalService', 'Menu', function ($auth, $state, $http, $rootScope, LocalService, Menu) {
  $rootScope.page_title = 'Login';
  var vm = this;
  
  var unsetResources = function () {
    LocalService.unset('user');
  };
  
  unsetResources();
  
  
  vm.loginError = false;
  vm.loginErrorText;
  
  
  vm.hideMsgError = function () {
    vm.loginError = false;
  };
  
  vm.login = function () {
    
    var credentials = {
      email: vm.email,
      password: vm.password
    }
    
    $auth.login(credentials).then(function () {
      return $http.get('/api/authenticate/user').then(function (response) {
        
        // Set the stringified user data into local storage
        LocalService.set('user', JSON.stringify(response.data.user));
        Menu.createMenu();
        $state.go('dashboard');
      });
      
      // Handle errors
    }, function (error) {
      vm.loginError = true;
      vm.loginErrorText = error.data.error;
      
    });
  }
  
}]);
