app.factory('Recaudador', ['$http', '$q', function ($http, $q) {
  
  return {
    getAll: function (data) {
      var defered = $q.defer();
      $http.get('/api/recaudadores/', {params: data})
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (error) {
          defered.reject(error);
        });
      return defered.promise;
    },
    
    create: function (data) {
      return $http.post('/api/recaudadores', data);
    }
    ,
    
    update: function (id, data) {
      return $http.put('/api/recaudadores/' + id, data);
    }
    
  }
}]);
