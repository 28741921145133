app.controller('PlanillaCtrl', ['$rootScope', '$scope', 'Recaudador',
  function ($rootScope, $scope, Recaudador) {
    $rootScope.page_title = 'Imprimir planilla de recaudo';
    $scope.recaudadores = [];
    $scope.recaudadoresSelected = [];
    
    var getRecaudadores = function () {
      var dataSend = {
        suscriptor_id: $rootScope.suscriptor.id,
        estado: true
      };
      $scope.promise = Recaudador.getAll(dataSend).then(function (data) {
        $scope.recaudadores = data.recaudadores;
        $scope.checkAll();
      });
      
    };
    
    getRecaudadores();
    
    $scope.checkAll = function () {
      $scope.recaudadoresSelected = $scope.recaudadores.map(function (item) {
        return item.id;
      });
    };
    
    
    $scope.generar = function () {
      // window.open('/api/planilla','_blank');
      var ids = JSON.stringify($scope.recaudadoresSelected);
      var suscriptor_id = $rootScope.suscriptor.id;
      var url = "api/planilla?ids=" + ids + "&suscriptor_id=" + suscriptor_id;
      window.open(url, 'Planilla de recaudo', '_blank');
    };
    
  }]);


