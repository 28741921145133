app.controller('CarteraCtrl', ['$rootScope', '$scope', 'Venta', 'Recaudador', 'filterFilter', '$uibModal', 'toastr',
  function ($rootScope, $scope, Venta, Recaudador, filterFilter, $uibModal, toastr) {
    $rootScope.page_title = 'Cartera de clientes por Recaudador';
    
    var vm = $scope;
    vm.recaudador = {};
    vm.recaudadores = [];
    vm.ventas = [];
    vm.venta = {};
    vm.carteraAction = false;
    
    //funcion para validar si un usuario puede reversar un descuadre
    var validarPermisoCartera = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id == 17) {
          vm.carteraAction = true;
        }
      });
    };
    
    validarPermisoCartera();
    if (!vm.carteraAction) {
      toastr.warning('No esta autorizado para realizar esta operacion', 'Adventencia', {
        closeButton: true
      });
      
      $state.go('dashboard');
    }
    
    
    //obtener lista de los recaudadores
    vm.getRecaudadores = function () {
      vm.promise = Recaudador.getAll({suscriptor_id: $rootScope.suscriptor.id})
        .then(function (data) {
          if ($rootScope.currentUser.admin === 1) {
            vm.recaudadores = data.recaudadores;
          } else {
            var obj = {'visible_for_users': 1}
            vm.recaudadores = filterFilter(data.recaudadores, obj);
          }
          
        });
    };
    
    vm.getRecaudadores();
    
    
    vm.calcularDias = function (venta) {
      vm.label = null;
      var hoy = moment(new Date(), 'YYYY-MM-DD');
      var fecha_venta = moment(venta.created_at, 'YYYY-MM-DD');
      var diff = hoy.diff(fecha_venta, 'days');
      return diff;
    };
    
    //******** funcion para sumar el total de las ventas ****************
    $scope.$watchCollection('filterList', function (list) {
      
      // console.log("la lista", list);
      
      vm.saldos = 0;
      angular.forEach(list, function (values) {
        
        vm.saldos = vm.saldos + parseFloat(values.nuevo_saldo);
        
      });
    });
    
    
    //metodo para cargar la lista de clientes cuando cambie el combo de recaudadores
    vm.cboRecaudadoresChange = function () {
      if (vm.recaudador !== null) {
        vm.promise = Venta.getVentasByRecaudador(vm.recaudador.id).then(function (data) {
          vm.ventas = data.ventas;
        });
      }
    };
    
    
    //metodo para ver los recaudos de una venta
    vm.showRecaudo = function (venta) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/tools/recaudos.html',
        controller: 'CarteraModalCtrl',
        size: 'lg',
        resolve: {
          venta: function () {
            return venta;
          }
        }
      });
      
    };
    
    
  }]);

app.controller('CarteraModalCtrl', ['$uibModalInstance', '$scope', 'Venta', 'venta',
  function ($uibModalInstance, $scope, Venta, venta) {
    var vm = $scope;
    vm.venta = venta;
    vm.title = "Recaudos por venta";
    vm.recaudos = [];
    vm.total = 0;
    
    //buscar la venta,
    function getVenta() {
      vm.busy = true;
      Venta.get(vm.venta.id).then(function (data) {
        vm.recaudos = data.venta.recaudos;
        angular.forEach(vm.recaudos, function (recaudo) {
          console.log(recaudo);
          if (recaudo.reversado == 0) {
            vm.total = vm.total + recaudo.valor;
          }
        });
        console.log("total:", vm.total);
        vm.busy = false;
      });
    };
    
    getVenta();
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
  }]);
  
