app.controller('ConsignacionesCtrl', ['$rootScope', '$scope', 'Consignacion', '$uibModal', 'toastr', 'Proveedor', 'Evento', '$confirm',
  function ($rootScope, $scope, Consignacion, $uibModal, toastr, Proveedor, Evento, $confirm) {
    $rootScope.page_title = 'Consignaciones';
    var vm = $scope;
    vm.dataInvalid = false;
    vm.reversarAction = false;
    var data = {};
    
    
    /******* CONFIGURACION DE LAS FECHA ********/
    $scope.today = function () {
      $scope.dt1 = new Date();
      $scope.dt2 = new Date();
    };
    $scope.today();
    
    $scope.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    
    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
    
    $scope.open2 = function () {
      $scope.popup2.opened = true;
    };
    
    $scope.popup1 = {
      opened: false
    };
    
    $scope.popup2 = {
      opened: false
    };
    
    $scope.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    }
    
    vm.buscar = function () {
      vm.getConsignaciones();
    };
    
    vm.total = function (val1, val2) {
      return parseInt(val1) + parseInt(val2);
    }
    
    vm.consignaciones = [];
    $scope.pageSize = 25;
    $scope.currentPage = 1;
    $scope.maxSize = 10;
    
    
    $scope.sortKey = 'nombre';
    $scope.criterio = '';
    
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    
    vm.getConsignaciones = function () {
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD'),
      };
      vm.promise = Consignacion.getAll(data).success(function (response) {
        $scope.consignaciones = response.consignaciones;
      }).error(function (data, status, headers, config) {
        toastr.error('Se ha presentado un error de comunicacion con el servidor.', 'Error');
        vm.consignaciones = [];
        return status;
      });
      
      
    }
    
    vm.getConsignaciones();
    
    
    //******** funcion para sumar el total de las consignaciones ****************
    $scope.$watchCollection('filterList', function (list) {
      $scope.montoTotal = 0;
      $scope.monto_recibido_total = 0;
      angular.forEach(list, function (values) {
        if (values.reversada === 0) {
          $scope.montoTotal = $scope.montoTotal + parseInt(values.monto);
          $scope.monto_recibido_total = $scope.monto_recibido_total + parseInt(values.monto_recibido);
        }
      })
    });
    
    
    $scope.consignacion = {};
    $scope.open = function () {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/consignaciones/form.html',
        controller: 'ConsignacionModalCtrl',
        resolve: {
          consignacion: function () {
            return $scope.consignacion;
          }
        }
      });
      
      modalInstance.result.then(function (consignacion) {
        var record = $scope.consignacion;
        record.suscriptor_id = $rootScope.suscriptor.id;
        record.user_id = $rootScope.currentUser.id;
        record.transaccion = "Manual";
        record.cliente_id = vm.consignacion.cliente.id;
        record.proveedor_id = vm.consignacion.proveedor.id;
        record.banco_id = vm.consignacion.banco.id;
        //record.recaudador_id = vm.consignacion.recaudador.id;
        record.tasa = parseFloat(vm.consignacion.proveedor.tasa_compra);
        record.monto = parseInt(vm.consignacion.monto);
        record.monto_recibido = (record.monto * record.tasa);
        var saldo_proveedor = parseInt(vm.consignacion.proveedor.saldo);
        var nuevo_saldo = (saldo_proveedor + record.monto + record.monto_recibido)
        record.saldo_proveedor = nuevo_saldo;
        
        $scope.promise = Consignacion.create(record).then(function (response) {
          //actualizar el saldo del proveedor
          //var saldo_proveedor = parseInt(vm.consignacion.proveedor.saldo);
          //var nuevo_saldo = (saldo_proveedor + record.monto + record.monto_recibido)
          data.saldo = nuevo_saldo;
          
          
          //actualizar el saldo del proveedor
          Proveedor.update(record.proveedor_id, data)
            .then(function (response) {
              //guardar el evento
              var evento = {};
              evento.evento = 'CONSIGNACION REGISTRADA';
              evento.numero = vm.consignacion.transaccion;
              evento.proveedor_id = record.proveedor_id;
              evento.valor = vm.consignacion.monto;
              evento.saldo = data.saldo;
              evento.cliente_id = vm.consignacion.cliente.id;
              evento.user_id = $rootScope.currentUser.id;
              evento.suscriptor_id = $rootScope.suscriptor.id;
              evento.tipo = '+';
              
              //GUARDA EL EVENTO
              Evento.create(evento).then(function (result) {
                toastr.info('Good!', 'Consignacion registrada exitosamente!');
                vm.consignacion = {};
                //vm.getConsignaciones();
                vm.consignacion = {};
                $scope.open();
              })
            });
          
          
        }, function (error) {
          toastr.error('Error', 'No se pudo guardar la informacion');
        }).$promise;
        
      }, function () {
        $scope.consignacion = {};
        //$log.info('Modal dismissed at: ' + new Date());
        vm.getConsignaciones();
        
      });
    }; //END FUNCTION OPEN
    
    vm.edit = function (consignacion) {
      $scope.consignacion = consignacion;
      vm.consignacion.estado = consignacion.estado == '1' ? true : false;
      $scope.open();
    };
    
    
    //funcion para validar si un usuario puede reversar una consignacion
    var validarPermisoReversar = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id = 21) {
          vm.reversarAction = true;
        }
      })
    };
    
    validarPermisoReversar();
    
    
    // FUNCION PARA REVERSAR UNA CONSIGNACION
    $scope.reversarConsignacion = function (consignacion) {
      var title = "Reversar consignación!";
      var texto = "Estas seguro de reversar la consignacion No. :" + consignacion.id + "?";
      vm.proveedor = {};
      $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
        .then(function () {
          data.reversada = true;
          Consignacion.update(consignacion.id, data)
            .then(
              function (response) {
                Proveedor.find(consignacion.proveedor.id).then(function (data, status, headers, config) {
                  vm.proveedor = data.proveedor;
                  vm.saldo = parseInt(vm.proveedor.saldo);
                  var monto = parseInt(consignacion.monto);
                  var monto_recibido = parseInt(consignacion.monto_recibido);
                  vm.nuevo_saldo = (vm.saldo - (monto + monto_recibido));
                  Proveedor.update(vm.proveedor.id, {saldo: vm.nuevo_saldo}).then(function (result) {
                    //guardar el evento
                    var evento = {};
                    evento.evento = 'CONSIGNACION REVERSADA';
                    evento.numero = consignacion.numero;
                    evento.proveedor_id = vm.proveedor.id;
                    evento.valor = consignacion.monto;
                    evento.saldo = vm.nuevo_saldo;
                    evento.cliente = consignacion.cliente.nombre;
                    evento.user_id = $rootScope.currentUser.id;
                    evento.suscriptor_id = $rootScope.suscriptor.id;
                    evento.tipo = '-';
                    
                    //GUARDA EL EVENTO
                    Evento.create(evento).then(function (result) {
                      toastr.info('Good!', 'Consignacion REVERSADA exitosamente!');
                      vm.getConsignaciones();
                    })
                  });
                  
                }); //fin buscar proveedor
                
              }
            );
          
        });
    }//fin remove
    
    
    //********************* CLONAR ************************
    vm.clonar = function (consignacion) {
      $scope.consignacion = consignacion;
      $scope.consignacion.numero = parseInt(consignacion.numero);
      $scope.consignacion.reversada = false;
      $scope.open();
    }
    
    
  }]);


app.controller('ConsignacionModalCtrl', ['$rootScope', '$scope', '$uibModalInstance', 'consignacion',
  'Banco', 'Departamento', 'Proveedor', 'Cliente', 'Recaudador', 'Ciudad',
  function ($rootScope, $scope, $uibModalInstance, consignacion, Banco, Departamento, Proveedor, Cliente, Recaudador, Ciudad) {
    var vm = $scope;
    
    
    /************* LLENAR LOS COMBOS ********/
    var data = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    }
    Banco.getAll({active: true}).success(function (data) {
      vm.bancos = data.bancos;
    });
    
    
    Proveedor.getActivos(data).then(function (data) {
      vm.proveedores = data.proveedores;
    });
    
    var data_cliente = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true,
      tipo_cliente_id: 2
    }
    
    //metodo para buscar los clientes segun proveedor
    vm.cboProveedorChange = function (proveedor) {
      if (proveedor == undefined) {
        vm.clientes = [];
      } else {
        Cliente.getAllForProveedor({proveedor_id: proveedor.id}).success(function (data) {
          vm.clientes = data.clientes;
        });
      }
      
    }
    
    
    /************* FIN LLENAR LOS COMBOS ********/
    
    
    $scope.userIsAdmin = $rootScope.currentUser.admin;
    
    vm.title = "";
    
    //************************CREAR EL FORMULARIO
    vm.consignacion = consignacion;
    
    if (!vm.consignacion.id) {
      vm.isnew = true;
      vm.consignacion.saldo = 0;
      vm.title = "Registrar nueva consignacion";
    } else {
      vm.title = "Clonar consignacion: " + vm.consignacion.numero;
      vm.isnew = false;
    }
    
    //*********** ENVIAR EL FORMULARIO
    $scope.checkIfEnterKeyWasPressed = function ($event) {
      var keyCode = $event.which || $event.keyCode;
      if (keyCode === 13) {
        setTimeout(function () {
          $('#btnSave').focus().select();
        }, 0);
      }
      
    };
    
    
    vm.ok = function () {
      $uibModalInstance.close(vm.consignacion);
    };
    
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    
    
  }]);
