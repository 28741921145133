app.factory('Consignacion', ['$http', '$q', '$timeout', function ($http, $q, $timeout) {
  
  return {
    getAll: function (data) {
      return $http.get('/api/consignaciones/', {params: data});
    },
    get: function (id, data) {
      return $http.get('/api/consignaciones/' + id, {params: data});
    },
    create: function (data) {
      var defered = $q.defer();
      $http.post('/api/consignaciones', data)
        .success(function (data) {
          defered.resolve(data);
        })
        .error(function (err) {
          defered.reject(err);
        });
      
      return defered.promise;
      
    }
    ,
    update: function (id, data) {
      return $http.put('/api/consignaciones/' + id, data);
    },
    findTransaccion: function (suscriptor_id, transaccion) {
      var defered = $q.defer();
      $timeout(function () {
        $http.get('/api/consignacion/suscriptor/' + suscriptor_id + '/transaccion/' + transaccion)
          .success(function (data) {
            defered.resolve(data);
          })
          .error(function (err) {
            defered.reject(err);
          });
      }, 100);
      return defered.promise;
    }
  };
}]);
