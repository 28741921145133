app.factory('Descuadre', ['$resource', function ($resource) {
  return $resource('/api/descuadres/:id',
    {id: "@id"},
    {
      'update': {method: 'PUT', isArray: false},
      'query': {method: 'GET', isArray: false},
      'getByRecaudador': {
        method: 'POST',
        isArray: false,
        url: '/api/descuadres/recaudador',
      },
    });
  
}]);
