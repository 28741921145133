app.controller('VentasCtrl', ['$rootScope', '$scope', 'Venta', '$uibModal', 'toastr', 'Proveedor', 'Evento', '$confirm',
  function ($rootScope, $scope, Venta, $uibModal, toastr, Proveedor, Evento, $confirm) {
    $rootScope.page_title = 'Ventas';
    var vm = $scope;
    vm.dataInvalid = false;
    vm.reversarAction = false;
    var data = {};
    $scope.isGroup = false;
    $scope.textBtnGroup = "Agrupar";
    $rootScope.proveedores = [];
    vm.proveedor_id = null;


    Proveedor.getAll({suscriptor_id: $rootScope.suscriptor.id}).then(function (data) {
      $rootScope.proveedores = data.proveedores;
    });


    //metodo para calcular la utilidad
    vm.utilidad = function (venta) {
      var tasa_utilidad = parseFloat(venta.tasa_compra - venta.tasa);
      var utilidad = venta.valor * tasa_utilidad;
      return utilidad;
    };

    $scope.groupBy = function () {
      $scope.ventasAgrupadas = [];
      $scope.isGroup = !$scope.isGroup;
      if ($scope.isGroup) {
        $scope.textBtnGroup = "Desagrupar";
        vm.totalUtilidad = 0;
        angular.forEach($rootScope.proveedores, function (pro) {
          var valor = 0;
          var utilidad = 0;

          var ventaAgrupada = {};

          if (pro.estado === 1) {
            angular.forEach($scope.ventas, function (venta) {

              if (venta.proveedor.id == pro.id && venta.reversada === 0) {
                valor = valor + venta.valor;
                utilidad = utilidad + vm.utilidad(venta);
              }


            }); //finc foreach ventas
            ventaAgrupada.proveedor = pro.nombre;
            ventaAgrupada.valor = valor;
            ventaAgrupada.utilidad = utilidad;
            vm.totalUtilidad = vm.totalUtilidad + utilidad;
            $scope.ventasAgrupadas.push(ventaAgrupada);
          }


        });

      } else {
        $scope.textBtnGroup = "Agrupar";

      }


    }


    /******* CONFIGURACION DE LAS FECHA ********/
    $scope.today = function () {
      $scope.dt1 = new Date();
      $scope.dt2 = new Date();
    };

    $scope.today();

    $scope.formats = ['yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];

    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };

    $scope.open2 = function () {
      $scope.popup2.opened = true;
    };

    $scope.popup1 = {
      opened: false
    };

    $scope.popup2 = {
      opened: false
    };

    $scope.changeSelect = function () {
      if (moment(vm.dt1).isSameOrBefore(vm.dt2)) {
        vm.dataInvalid = false;
      } else {
        vm.dataInvalid = true;
      }
    };

    vm.buscar = function () {
      vm.getVentas();
    };


    vm.ventas = [];
    $scope.pageSize = 25;
    $scope.currentPage = 1;
    $scope.maxSize = 10;


    $scope.sortKey = 'nombre';
    $scope.criterio = '';

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };


    //*********************************************** METODO GETVENTAS********
    vm.getVentas = function () {
      vm.textBtnGroup = "Agrupar";
      vm.isGroup = false;
      var data = {
        suscriptor_id: $rootScope.suscriptor.id,
        startDate: moment(vm.dt1).format('YYYY-MM-DD'),
        endDate: moment(vm.dt2).format('YYYY-MM-DD')
      };
      vm.promise = Venta.getAll(data).success(function (response) {
        $scope.ventas = response.ventas;
      }).error(function (error, status) {
        console.log(error);
        toastr.error('Se ha presentado un error de comunicacion con el servidor.', 'Error');
        vm.ventas = [];
        return status;
      });


    };

    //llamar al metodo para obtener las ventas
    vm.getVentas();


    //******** funcion para sumar el total de las ventas ****************
    $scope.$watchCollection('filterList', function (list) {
      $scope.valores = 0;
      $scope.comisiones = 0;
      $scope.valores_enviados = 0;
      $scope.utilidades = 0;
      angular.forEach(list, function (values) {
        if (values.reversada === 0) {
          $scope.valores = $scope.valores + parseFloat(values.valor);
          $scope.comisiones = $scope.comisiones + parseFloat(values.comision);
          $scope.valores_enviados = $scope.valores_enviados + parseFloat(values.neta);
          $scope.utilidades = $scope.utilidades + vm.utilidad(values);
        }
      });
    });


    $scope.venta = {};
    $scope.open = function () {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'templates/ventas/form.html',
        controller: 'VentaModalCtrl2',
        resolve: {
          venta: function () {
            return $scope.venta;
          }
        }
      });

      modalInstance.result.then(function (venta) {


      }, function () {
        $scope.venta = {};
        //$log.info('Modal dismissed at: ' + new Date());
        vm.getVentas();

      });
    }; //END FUNCTION OPEN

    vm.edit = function (venta) {
      $scope.venta = venta;
      vm.venta.estado = venta.estado === '1' ? true : false;
      $scope.open();
    };


    //funcion para validar si un usuario puede reversar una venta
    var validarPermisoReversar = function () {
      angular.forEach($rootScope.menu_private, function (permiso) {
        if (permiso.id == 21) {
          vm.reversarAction = true;
        }
      });
    };

    validarPermisoReversar();


    // FUNCION PARA REVERSAR UNA CONSIGNACION
    $scope.reversarVenta = function (venta) {
      var hoy = moment(new Date(), 'YYYY-MM-DD');
      var fecha_venta = moment(venta.created_at, 'YYYY-MM-DD');
      var diff = hoy.diff(fecha_venta, 'days');

      //console.log("Hoy:", diff, "Fecha de la venta:", fecha_venta, "Diff:", diff);

      if (!vm.reversarAction && diff >= 1) {
        toastr.warning("No está autorizado para esta operación!", 'Advertencia', {
          closeButton: true
        });
        return;

      }

      var title = "Reversar Venta!";
      var texto = "Estas seguro de reversar la venta No. :" + venta.id + "?";
      vm.proveedor = {};
      $confirm({text: texto, title: title, ok: 'Yes', cancel: 'No'})
        .then(function () {
          data.reversada = true;
          data.saldo_anterior = 0;
          data.nuevo_saldo = 0;
          data.fecha_reversada = new Date();
          Venta.update(venta.id, data)
            .then(
              function (response) {
                Proveedor.find(venta.proveedor.id).then(function (data, status, headers, config) {
                  vm.proveedor = data.proveedor;
                  vm.saldo = parseInt(vm.proveedor.saldo);
                  var neta = parseInt(venta.neta);
                  vm.nuevo_saldo = (vm.saldo + neta);
                  Proveedor.update(vm.proveedor.id, {saldo: vm.nuevo_saldo}).then(function (result) {
                    //guardar el evento
                    var evento = {};
                    evento.evento = 'VENTA REVERSADA';
                    evento.numero = venta.id;
                    evento.proveedor_id = venta.proveedor.id;
                    evento.valor = venta.neta;
                    evento.saldo = vm.nuevo_saldo;
                    evento.cliente_id = venta.cliente.id;
                    evento.user_id = $rootScope.currentUser.id;
                    evento.suscriptor_id = $rootScope.suscriptor.id;
                    evento.tipo = '+';

                    //GUARDA EL EVENTO
                    Evento.create(evento).then(function (result) {
                      toastr.info('Good!', 'Venta REVERSADA exitosamente!');
                      venta.reversada = '1';
                    });
                  });

                }); //fin buscar proveedor

              }
            );
        });
    };//fin remove


  }]);


app.controller('VentaModalCtrl', ['$rootScope', '$scope', '$uibModalInstance',
  'Proveedor', 'Cliente', 'Venta', 'Evento', '$timeout',
  function ($rootScope, $scope, $uibModalInstance, Proveedor, Cliente, Venta, Evento, $timeout) {
    var vm = $scope;


    vm.formValid = false;
    vm.venta = {};
    vm.saldo_proveedor = 0;
    vm.cliente = null;

    $scope.$watch('selectedCliente', function (value) {
      if (value == null) {
        vm.formValid = false;
        vm.venta.tasa = null;
        vm.venta.proveedor = -1;
        vm.venta.valor = null;
        vm.venta.neta = null;

      } else {
        var proveedor_id = vm.selectedCliente.originalObject.proveedor.id;
        vm.cliente = vm.selectedCliente.originalObject;
        vm.ventaCreada = false;
        Proveedor.find(proveedor_id).then(function (data) {
          vm.formValid = true;
          vm.venta.proveedor = data.proveedor;
          vm.venta.tasa = value.originalObject.tasa;
          vm.saldo_proveedor = data.proveedor.saldo;
        });


        setTimeout(function () {
          $("#valor").focus();
        }, 0);
      }
    });


    vm.getProveedorProperties = function (proveedor) {
      if (vm.venta.proveedor != null) {
        Proveedor.find(proveedor.id).then(function (data) {
          vm.venta.proveedor = data.proveedor;
          vm.saldo_proveedor = data.proveedor.saldo;
        });

        vm.venta.tasa = vm.venta.proveedor.tvp;
        calcularVenta();
      }
    };


    $scope.checkIfEnterKeyWasPressed = function ($event) {
      var keyCode = $event.which || $event.keyCode;
      if (keyCode === 13) {
        calcularVenta();
      } else {
        vm.venta.neta = 0;
        vm.formValid = false;
      }

    };


    $scope.tasaEnterKeyWasPressed = function ($event) {
      var keyCode = $event.which || $event.keyCode;
      if (keyCode === 13) {
        $('#valor').focus();
      }

    };


    //metodo calcularVenta en ventasCtrl
    var calcularVenta = function () {
      console.log("Etoy en ventas");
      var tasa = parseFloat(vm.venta.tasa);
      var valor = parseInt(vm.venta.valor);
      vm.venta.neta = (valor + (valor * tasa));
      vm.saldo_proveedor = (vm.venta.proveedor.saldo - vm.venta.neta);
      vm.formValid = true;

      setTimeout(function () {
        $('#btnGuardar').focus();
      }, 0);
    };


    /************* LLENAR LOS COMBOS ********/
    var data = {
      suscriptor_id: $rootScope.suscriptor.id,
      estado: true
    };


    Cliente.getAllForVentas(data).success(function (data) {
      vm.clientes = data.clientes;
    });


    /************* FIN LLENAR LOS COMBOS ********/



    //*********** ENVIAR EL FORMULARIO

    vm.guardarVenta = function () {
      //guardar la venta


      var record = {};
      record.suscriptor_id = $rootScope.suscriptor.id;
      record.valor = parseInt(vm.venta.valor);
      record.tasa = parseFloat(vm.venta.tasa);
      record.neta = parseFloat(vm.venta.neta);
      record.comision = parseFloat(record.valor * record.tasa);
      record.saldo_anterior = parseInt(vm.venta.valor);
      record.nuevo_saldo = parseInt(vm.venta.valor);
      record.reversada = false;
      record.automatica = 0;
      record.proveedor_id = vm.venta.proveedor.id;
      record.tasa_compra = vm.venta.proveedor.tasa_compra;
      record.user_id = $rootScope.currentUser.id;
      record.cliente_id = vm.selectedCliente.originalObject.id;


      Proveedor.find(vm.venta.proveedor.id).then(function (data) {
        vm.proveedor = data.proveedor;
        var saldo_proveedor = parseInt(vm.proveedor.saldo);
        var neta = parseInt(vm.venta.neta);
        var nuevo_saldo = (saldo_proveedor - neta);
        vm.saldo_proveedor = nuevo_saldo;
      });

      record.saldo_proveedor = vm.saldo_proveedor;


      $scope.promise = Venta.create(record)
        .then(function (response) {
          $scope.venta = response.venta;
          $scope.venta_id = response.id;
          vm.proveedor_id = response.venta.proveedor_id;

          //actualizar el saldo del proveedor
          var dataProveedor = {
            saldo: record.saldo_proveedor
          };

          Proveedor.update(record.proveedor_id, dataProveedor).then(function (response) {
            //guardar el evento
            var evento = {};
            evento.evento = 'VENTA REGISTRADA';
            evento.numero = $scope.venta_id;
            evento.valor = vm.venta.valor;
            evento.saldo = dataProveedor.saldo;
            evento.cliente_id = record.cliente_id;
            evento.user_id = $rootScope.currentUser.id;
            evento.suscriptor_id = $rootScope.suscriptor.id;
            evento.tipo = '-';
            evento.proveedor_id = vm.proveedor_id;


            //GUARDA EL EVENTO
            Evento.create(evento).then(function (result) {
              vm.ventaCreada = true;
              vm.saldo_proveedor = dataProveedor.saldo;
              $timeout(function () {
                $('#cliente_value').focus();
                $('#cliente_value').val('');
                //vm.selectedCliente = null;
                vm.venta = {};
                vm.ventaCreada = false;

              }, 500)
            })
          });
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  }]);
